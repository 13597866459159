import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ampli } from "../../ampli";
import { TrendChart } from "../TrendChart/TrendChart";
import { Loader } from "../Loader/Loader";
import { useCurrentUser } from "../../contexts/CurrentUser";
import Select from 'react-select';
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { Radiobutton } from "../DropdownMenu/Radiobutton";
import { ButtonsList } from "../DropdownMenu/ButtonsList";
import { buildImageUrl } from "../../utils/downloadChart";
import styles from '../../pages/NarrativePage/NarrativePage.module.scss';
import { palette } from "../../utils/colors";
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';


export const EvolutionChart = ({ data, narrative, onChange, showMessageManipulationIndex, chartTab, agregation, breakdown, view }) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [totalEvolutionChart, setTotalEvolutionChart] = useState(chartTab);
  const [isTrendChartMenuOpen, setIsTrendChartMenuOpen] = useState(false);
  const trendChartRef = useRef(null);
  const trendChartCanvasRef = useRef(null);

  const statsAggregationOptions = [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ];
  const statsBreakdownOptions = [
    { value: null, label: t('All') },
    { value: 'PLATFORM', label: t('Platform') },
    { value: 'COUNTRY', label: t('Country') },
    { value: 'ACTOR_GROUP', label: t('Actor group') },
  ];

  const viewOptions = [
    {value: 'STANDARD', name: t('Standard')},
    {value: 'VALUES', name: t('Values')},
    {value: 'POINTS', name: t('Points')}
  ];

  const mapViewOptions = {
    'STANDARD': {value: 'STANDARD', name: t('Standard')},
    'VALUES': {value: 'VALUES', name: t('Values')},
    'POINTS': {value: 'POINTS', name: t('Points')}
  }

  const [statsAggregation, setStatsAggregation] = useState(
    agregation || statsAggregationOptions[0],
  );

  const [statsBreakdown, setStatsBreakdown] = useState(
    breakdown || statsBreakdownOptions[0],
  );

  const [chartViewOptions, setChartViewOptions] = useState(view || viewOptions[0]);

  useEffect(() => {
    if(data?.objects[0].stats?.length > 30 && data?.objects[0].stats?.length < 180) {
      setStatsAggregation({ value: 'WEEK', label: t('Week') })
    } else if(data?.objects[0].stats?.length >= 180) {
      setStatsAggregation({ value: 'MONTH', label: t('Month') })
    }
  }, [])


  useEffect(() => {
    onChange(totalEvolutionChart, statsAggregation, statsBreakdown, chartViewOptions)
  }, [statsAggregation, statsBreakdown, chartViewOptions, totalEvolutionChart])

  const aggregationSelect = (
    <Select
      className="chart-select"
      onChange={(data) => {
        ampli.track({
          event_type: 'Change granularity in evolution chart',
          event_properties: {
            user_id: currentUser?.id,
            workspace_id: currentUser?.workspace_id,
            narrative_id: narrative.id,
            granularity: data.label,
          }
        });
        setStatsAggregation(data);
      }}
      value={statsAggregation}
      options={statsAggregationOptions}
    />
  );
  const breakdownSelect = (
    <Select
      className="chart-select"
      onChange={(data) => {
        ampli.track({
          event_type: 'Change breakdown in evolution chart',
          event_properties: {
            user_id: currentUser?.id,
            workspace_id: currentUser?.workspace_id,
            narrative_id: narrative.id,
            breakdown: data.label,
          }
        });
        setStatsBreakdown(data);
      }}
      value={statsBreakdown}
      options={statsBreakdownOptions}
    />
  );

  let narrativeColor = null;
  if (data) {
    narrativeColor = Object.fromEntries(
      data?.objects?.map((narrative, i) => [narrative.id, palette[i % palette.length]])
    );
  }

  return(
    <div className="report-section">
        <h3 className="trend-chart-controlls">
          {t('Evolution')}
          <button
            type="button"
            className={totalEvolutionChart === 'publications' ? 'active' : ''}
            onClick={() => {
              ampli.track({
                event_type: 'Change metric in evolution chart',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  narrative_id: narrative.id,
                  metric: 'publications',
                }
              });
              setTotalEvolutionChart('publications');
            }}
          >
            <span>{t('Messages')}</span>
          </button>
          <button
            type="button"
            className={totalEvolutionChart === 'impressions' ? 'active' : ''}
            onClick={() => {
              ampli.track({
                event_type: 'Change metric in evolution chart',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  narrative_id: narrative.id,
                  metric: 'impressions',
                }
              });
              setTotalEvolutionChart('impressions');
            }}
          >
            <span>{t('Views')}</span>
          </button>
          <button
            type="button"
            className={totalEvolutionChart === 'engagement' ? 'active' : ''}
            onClick={() => {
              ampli.track({
                event_type: 'Change metric in evolution chart',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  narrative_id: narrative.id,
                  metric: 'engagement',
                }
              });
              setTotalEvolutionChart('engagement');
            }}
          >
            <span>{t('Reactions')}</span>
          </button>
          {showMessageManipulationIndex ? <button
            type="button"
            className={totalEvolutionChart === 'manipulation_index' ? 'active' : ''}
            onClick={() => {
              ampli.track({
                event_type: 'Change metric in evolution chart',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  narrative_id: narrative.id,
                  metric: 'manipulation_index',
                }
              });
              setTotalEvolutionChart('manipulation_index');
            }}
          >
            <span>{t('Manipulation index')}</span>
          </button> : ''}
        </h3>

        <div className="card">
          <div className={"card-header " + styles.cardHeader}>
            <div className={'card-controlls ' + styles.cardControls}>
                {breakdownSelect}{aggregationSelect}
                <DropdownMenu
                  icon={<SettingsIcon/>}
                  isOpen={isTrendChartMenuOpen}
                  onClick={() => setIsTrendChartMenuOpen(!isTrendChartMenuOpen)}
                  menuClassName='chartMenu'
                  header={t('Chart view')}
                >
                  <Radiobutton 
                    itemsList={viewOptions}
                    current={chartViewOptions.value}
                    onChange={(value) => setChartViewOptions(mapViewOptions[value])}
                  />

                  <ButtonsList
                    itemsList={[{value: 'PNG', name: t('Download as PNG')}, {value: 'SVG', name: t('Download as SVG')}]}
                    header={t('Download chart')}
                    onClick={(value) => {
                      let name = `${narrative.name}-evolution-chart-${statsBreakdown.label}-${statsAggregation.label}`
                      buildImageUrl(trendChartRef, trendChartCanvasRef, value, name)
                    }}
                  />
                  
                </DropdownMenu>
            </div>
          </div>
          <div className="card-body">
            {!data ? <Loader/> : <TrendChart
              rawdata={data?.objects}
              metric={totalEvolutionChart}
              showLegend={true}
              narrativeColor={narrativeColor}
              view={chartViewOptions}
              svgRef={trendChartRef}
              canvasRef={trendChartCanvasRef}
            />}
          </div>
        </div>
      </div>
  )
}
