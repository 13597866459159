import { useState, useEffect, useCallback, useRef } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import * as d3 from 'd3';
import { ampli } from "../../ampli";
import { subDays, addDays, format, parseISO, startOfWeek, setHours, setMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { API, ShareAPI } from "../../API";
import { useCurrentUser } from "../../contexts/CurrentUser";
import { Loader } from "../../components/Loader/Loader";
import { StoryContent } from "../../components/StoryContent/StoryContent";
import { StoriesFeed } from "../../components/StoriesFeed/StoriesFeed";
import { enGB, is, uk } from 'date-fns/locale';
import DatePicker from 'react-datepicker';

import styles from './TopicReportPageNew.module.scss';
import './TopicReportPage.scss';

const opinions = {objects: [
  {
    id: 1,
    "publication_date": "2024-09-02T07:48:42.560703+00:00",
    title: `Cultural Bridges: The Artistic Exchange`,
    content: 'During his visit, Putin attended a series of cultural events that showcased the rich heritage of both countries. A highlight was the joint ballet performance titled "Harmony and Courage," which symbolized the resilience and unity of the two nations. This event not only entertained but also fostered a deeper cultural understanding between Russia and North Korea.',
    "rating": 87,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'positive', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
  {
    id: 2,
    publication_date: "2024-09-01T07:48:42.560703+00:00",
    title: `Strengthening Ties: The Bilateral Agreement`,
    content: 'A landmark moment of the visit was the signing of the "Pyongyang-Moscow Friendship Treaty," aimed at enhancing economic cooperation and mutual security. The agreement included plans for joint ventures in technology and infrastructure, marking a new era in the diplomatic relationship between Russia and North Korea.',
    "rating": 68,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'neutral', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
  {
    id: 3,
    publication_date: "2024-09-03T07:48:42.560703+00:00",
    title: `The Peace Garden Ceremony`,
    content: 'An unforgettable event was the inauguration of the Peace Garden in Pyongyang, attended by Putin and Kim Jong Un. This garden, featuring flora from both countries, was established as a living symbol of peace and friendship. The ceremony included the planting of a "Tree of Unity," with both leaders participating, symbolizing their commitment to a peaceful future.',
    "rating": 72,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'negative', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
  {
    id: 4,
    publication_date: "2024-09-01T07:48:42.560703+00:00",
    title: `Humanitarian Horizons: A New Project Launch`,
    content: `Amidst the diplomatic engagements, a significant announcement was the launch of the "Brighter Future" initiative, a joint humanitarian project aimed at providing educational resources to underprivileged areas in both countries. This initiative underscored the visit's focus on not just political, but also social and humanitarian collaboration.`,
    "rating": 85,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'positive', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
  {
    id: 5,
    publication_date: "2024-09-05T07:48:42.560703+00:00",
    title: `An Unexpected Friendship`,
    content: 'The visit took an unexpected turn when a sudden storm stranded both leaders at a remote cultural site. The incident, far from being a setback, allowed for unplanned, genuine interactions between the two. Sharing stories over a campfire, they found common ground, leading to a spontaneous agreement on cultural exchange programs, further solidifying their countries’ bonds.',
    "rating": 77,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'positive', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
]}

const formatNumberSignificant = d3.format('.3~s',);


export const TopicReportPageNew = ({isShare = false}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const { topicReportId, narrativeId } = useParams();
  const [searchParams] = useSearchParams();
  const [report, setReport] = useState(null);
  const [currentStory, setCurrentStory] = useState(null);
  const [currentOpininon, setCurrentOpinion] = useState(null);
  const [currentTab, setCurrentTab] = useState('stories');
  const [events, setEvents] = useState();
  const [sourceGroups, setSourceGroups] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sentiment, setSentiment] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sorting, setSorting] = useState({
    fieldName: 'recency',
    isAscending: false,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [cursor, setCursor] = useState(null);
  const [isData, setIsData] = useState(true);
  const [hasMoreEvents, setHasMoreEvents] = useState(true);
  const [eventsPage, setEventsPage] = useState(1);
  const scrollRef = useRef(null);
  const caseScrollRef = useRef(null);
  const [updatedDateTime, setUpdatedDateTime] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const updateDateTime = (date) => {
    if (!currentUser.is_super_admin) {
      return;
    }

    API.fetch('PATCH', `/API/v1/topic-modeling/${report.id}/metadata`, null, {
      last_updated_at: date,
    }).then(() => {
      fetchReport();
    });
  };

  let size = 20;

  const sortingQuery = sorting.fieldName
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  const fetchReport = useCallback(() => {
    let id = topicReportId || narrativeId;
    API.fetch('GET', `/API/v1/topic-modeling/${id}`).then((data) => {
      setReport(data);
      let currentDate = new Date();

      if(data.dates_preset) {
        let preset = data.dates_preset;

        if(preset === 'FIXED') {
          setStartDate(new Date(data.preset_start_date));
          setEndDate(new Date(data.preset_end_date));
        } else if(preset === 'LAST_7_DAYS') {
          setStartDate(subDays(currentDate, 7));
          setEndDate(currentDate);
        } else if(preset === 'YESTERDAY') {
          setStartDate(subDays(currentDate, 1));
          setEndDate(subDays(currentDate, 1));
        } else if(preset === 'YESTERDAY_TODAY') {
          setStartDate(subDays(currentDate, 1));
          setEndDate(currentDate);
        } else if(preset === 'CURRENT_WEEK') {
          setStartDate(addDays(startOfWeek(currentDate), 1));
          setEndDate(currentDate);
        } else if (preset === 'LAST_WEEK') {
          setStartDate(addDays(startOfWeek(subDays(currentDate, 7)), 1));
          setEndDate(startOfWeek(subDays(currentDate, 1)));
        }
      } else {
        setStartDate(new Date(data.start_date));
        setEndDate(new Date(data.end_date ?? currentDate));
      }

      if(data.preset_sort) {
        setSorting({fieldName: data.preset_sort.replace('-', ''), isAscending: !data.preset_sort.startsWith('-')});
      }

      if(data.last_updated_at) {
        setUpdatedDateTime(format(parseISO(data.last_updated_at + 'Z'), 'yyyy-LL-dd HH:mm'));
      }
      
    });
  }, [topicReportId, narrativeId]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  const fetchEvents = useCallback(() => {
    if (!report) {
      return;
    }

    setEvents()
    setCurrentStory(null)
    const urlParams = new URLSearchParams();
    urlParams.set('page', 1);
    urlParams.set('size', size);
    urlParams.set('sort', sortingQuery);
    if(startDate) {
      urlParams.set('date_from',format(startDate, 'yyyy-LL-dd'))
    }
    if(endDate) {
    urlParams.set('date_to', format(endDate, 'yyyy-LL-dd'))
    }

    if (sourceGroups.length > 0) {
      sourceGroups.forEach((source) => {
        urlParams.append('source_group_ids', source.value);
      }
      )
    }

    if (countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      }
      )
    }

    if (sentiment.length > 0) {
      sentiment.forEach((s) => {
        urlParams.append('opinion_balance', s);
      }
      )
    }
    if(searchQuery.trim().length > 0) {
      urlParams.set('q', searchQuery);
    }

    const url = `/API/v1/topic-modeling/${report?.id}/events?${urlParams.toString()}`;

    API.fetch('GET', url).then((data) => {
      if(data.objects.length === 0) {
        setEvents(data);
        setIsData(false);
        setHasMoreEvents(false);
      } else {
        if(isData === false) {
          setIsData(true);
        }
        data.objects.length < size ? setHasMoreEvents(false) : setHasMoreEvents(true);
        setEvents(data);
        setCurrentStory(data?.objects[0]);

      }
      
    });
  }
  , [report, searchQuery, sorting, startDate, endDate, sentiment, sourceGroups, countries]);

  useEffect(() => { 
    fetchEvents();
  }, [fetchEvents]);

  const fetchMoreEvents = () => {
    const urlParams = new URLSearchParams();
    urlParams.set('page', eventsPage + 1);
    urlParams.set('size', size);
    urlParams.set('sort', sortingQuery);
    if(startDate) {
      urlParams.set('date_from',format(startDate, 'yyyy-LL-dd'))
    }
    if(endDate) {
    urlParams.set('date_to', format(endDate, 'yyyy-LL-dd'))
    }

    if (sourceGroups.length > 0) {
      sourceGroups.forEach((source) => {
        urlParams.append('source_group_ids', source.value);
      }
      )
    }

    if (countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      }
      )
    }

    if (sentiment.length > 0) {
      sentiment.forEach((s) => {
        urlParams.append('opinion_balance', s);
      }
      )
    }

    if(searchQuery.trim().length > 0) {
      urlParams.set('q', searchQuery);
    }

    const url = `/API/v1/topic-modeling/${report?.id}/events?${urlParams.toString()}`;

    API.fetch('GET', url).then((data) => {
      setEventsPage(eventsPage + 1);
      setEvents({...events, objects: [...events.objects, ...data.objects], page: data.page});
      data.objects.length < size ? setHasMoreEvents(false) : setHasMoreEvents(true);
    });
  };

  const handleStoryEdit = (story) => {
    let newList = events.objects.map((s) => {
      if (s.id === story.id) {
        s.title = story.title;
        s.description = story.description;
        return s;
      } else {
        return s;
      }
    })
    setEvents({...events, objects: newList})
  }

  useEffect(() => {
    ampli.track({
      event_type: 'Open  topic report',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        report_id: report?.id,
      }
    });
  }, []);

  let dateLocale = enGB;
  let dateFormat = 'LLLL dd, yyyy HH:mm';
  // let dateFormat = 'dd LLLL yyyy';
  if (
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
    dateLocale = uk;
    dateFormat = 'dd LLL yyyy, HH:mm';
  } else {
    dateLocale = enGB;
  }

  if(!report) {
    return <Loader />;
  }

  return (
    <div className={styles.root} ref={caseScrollRef}>
      <div className="list-content">
        {topicReportId ? (
          <div className="page-header">
            <div className="breadcrumb">
              <span>
                <Link to="/topic_reports">{t('Topic discovery')}</Link>
              </span>
              <span>{report?.name}</span>
            </div>
            <div className="controls"></div>
          </div>
        ) : (
          ''
        )}
        {topicReportId ? (
          <div className={styles.parametersPanel} ref={scrollRef}>
            <div
              className={
                !currentUser.is_super_admin
                  ? styles.parameterContainer
                  : styles.parametersContainerEditable
              }
            >
              <span className={styles.parameterTitle}>
                {t('Last updated time:')}
              </span>
              <span
                className={styles.parameterValue}
                onClick={() => {
                  if (currentUser.is_super_admin) {
                    setIsCalendarOpen(true);
                  } else {
                    return;
                  }
                }}
              >
                {!isCalendarOpen ? (
                  report?.last_updated_at ? (
                    format(
                      parseISO(report?.last_updated_at + 'Z'),
                      dateFormat,
                      {
                        locale: dateLocale,
                      },
                    )
                  ) : (
                    t('N/A')
                  )
                ) : (
                  <DatePicker
                    calendarClassName={styles.updateDateTimeCalendar}
                    selected={
                      updatedDateTime ? new Date(updatedDateTime) : null
                    }
                    onChange={(date) => {
                      setUpdatedDateTime(date);
                      setIsCalendarOpen(false);
                    }}
                    onSelect={(date) => {
                      return;
                    }}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy H:mm"
                    timeFormat="HH:mm"
                    injectTimes={[
                      setHours(setMinutes(new Date(), 1), 0),
                      setHours(setMinutes(new Date(), 5), 12),
                      setHours(setMinutes(new Date(), 59), 23),
                    ]}
                    showTimeSelect
                    onClickOutside={() => setIsCalendarOpen(false)}
                    autoFocus={true}
                    shouldCloseOnSelect={false}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setIsCalendarOpen(false);
                        updateDateTime(
                          format(new Date(updatedDateTime), 'yyyy-LL-dd HH:mm'),
                        );
                      }
                    }}
                  />
                )}
              </span>
            </div>
            {currentUser?.is_super_admin ? (
              <div className={styles.parameterContainer}>
                <span className={styles.parameterTitle}>
                  {t('Content analyzed:')}
                </span>
                <span className={styles.parameterValue}>
                  {report?.content_analyzed
                    ? report?.content_analyzed
                    : formatNumberSignificant(0)}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        {/* <div className="report-section">
        <div className={styles.reportHeader}>
          <h2>{report?.name}</h2>
          <p>{report?.description}</p>
        </div>
      </div> */}
        <div className={styles.reportContent}>
          <div className={styles.reportFeed}>
            <StoriesFeed
              report={report}
              stories={events?.objects}
              opinions={opinions.objects}
              currentStory={currentStory}
              onStoryChange={setCurrentStory}
              onOpinionChange={setCurrentOpinion}
              onTabChange={setCurrentTab}
              onFilterChange={(sourceGroups, countries, sentiment) => {
                setSourceGroups(sourceGroups);
                setCountries(countries);
                setSentiment(sentiment);
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate);
                setEndDate(endDate);
                setEventsPage(1);
                setCursor(null);
              }}
              onSortingChange={(value) => {
                setSorting(value);
                setEventsPage(1);
                setCursor(null);
              }}
              sourceGroups={sourceGroups}
              countries={countries}
              sentiment={sentiment}
              startDate={startDate}
              endDate={endDate}
              sorting={sorting}
              searchQuery={searchQuery}
              onStoryEdit={(story) => {
                handleStoryEdit(story);
              }}
              onStoryDelete={() => {
                fetchEvents();
              }}
              hasMoreEvents={hasMoreEvents}
              fetchMoreEvents={() => fetchMoreEvents()}
              onSearch={(value) => {
                setSearchQuery(value);
                setEventsPage(1);
              }}
            />
          </div>
          <div className={styles.reportStory}>
            <StoryContent
              report={report}
              story={currentStory}
              isShare={isShare}
              isData={isData}
              sourceGroups={sourceGroups}
              countries={countries}
              sentiment={sentiment}
              startDate={startDate}
              endDate={endDate}
              scrollRef={topicReportId ? scrollRef : caseScrollRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};