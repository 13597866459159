import { useTranslation } from 'react-i18next';
import { API } from '../../../API';
import Async from 'react-select/async';
import { useEffect, useState, useCallback } from 'react';

export const AffiliationCountries = ({onChange, selected = [], onClose}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const selectAllOption = {value: 'all', label: t('Select all')};

  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries`).then((data) => {
      let options = data.objects.slice(0, 10).map(country => {return {value: country.id, label: country.name}});
      setCountries(options);
    });
  }, []);

  useEffect(getAllCountries, [getAllCountries]);

  useEffect(() => {
    if(selected.length > 0 && selected[0].value === 'all') {
      setSelectAll(true)
    }
  }, [])
  

  const getCountries = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/countries?q=${inputValue}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((source) => {
            return {value: source.id, label: source.name};
          })
      );
    });
  };

  let countriesPromiseTimeout;
  const countriesPromiseOptions = function (inputValue) {
    if (countriesPromiseTimeout !== null) {
      clearTimeout(countriesPromiseTimeout);
    }

    if (! inputValue) {
        return [];
    }

    return new Promise((resolve) => {
      countriesPromiseTimeout = setTimeout(() => {
        getCountries(inputValue, resolve);
      }, 1000);
    });
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if(!selectAll === true) {
      onChange([selectAllOption]);
    } else {
      onChange([])
    }
    
  }



  if (!countries) return

  return(
    <div className='chooser affiliations-search-filter'>
       <div className='control' onClick={() => handleSelectAll()}>{selectAll ? t('Deselect all') : t('Select all')}</div>
      <Async
        className="select-filter"
        classNamePrefix="select-filter"
        cacheOptions
        defaultOptions={countries}
        isMulti={true}
        loadOptions={countriesPromiseOptions}
        placeholder={t('Please enter at least 1 symbol')}
        noOptionsMessage={()=> t('Please enter at least 1 symbol')}
        loadingMessage={() => t('Loading...')}
        name="affiliation-countries"
        value={selected}
        onChange={(values) => onChange(values)}
        onMenuClose={() => onClose()}
        isDisabled = {selectAll}
      />
    </div>
  )
}