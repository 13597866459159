
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platforms } from "./Platforms";
import { Countries } from './Countries';
import { Verification } from './Verification';
import { Audience } from './Audience';
import { Compromised } from './Compromised';
import { Bots } from './Bots';
import { StateAffiliated } from './StateAffiliated';
import { TopActorsByPosts } from './TopActorsByPosts';
import { TopTagsByPosts } from './TopTagsByPosts';
import {BreakdownsContext} from './contexts/BreakdownsContext';
import { NarrativeTreeChartModal } from '../TreeChartModal/NarrativeTreeChartModal';
import { TopsWidget } from './TopsWidget';
import { ampli } from '../../../ampli';
import { useCurrentUser } from '../../../contexts/CurrentUser';

import styles from '../NarrativePage.module.scss';

export const Breakdowns = ({isShare, narrative}) => {
  const { t } = useTranslation();

  const {
    isModalOpen,
    closeModal,
    sourceGroupFilter,
    platformsFilter,
    sourcesTypesFilter,
    countriesFilter,
    sourcesSubtypesFilter,
    affiliationCountryId,
    audienceFilter,
    sourceFilter,
  } = useContext(BreakdownsContext);

  const { currentUser } = useCurrentUser();

  useEffect(() => {
    ampli.track({
      event_type: 'Open Actors tab',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        narrative_id: narrative?.id,
      }
    });
  }, []);
  
  return(
    <div className={styles.breakdownsContainer}>

    <h2>{t('Breakdowns')}</h2>
    {/* <div>{t("Click on any name, digit, actor or source name to view it’s details. Or simply click the 'View details' button to view details of the widget")}</div> */}

    <div className={styles.breakdownsRow}>
      <Platforms narrative={narrative} isShare={isShare}/>
      <Countries narrative={narrative} isShare={isShare}/>
      <Verification narrative={narrative} isShare={isShare}/>
    </div>

    <div className={styles.breakdownsRow}>
      <Audience narrative={narrative} isShare={isShare}/>
    </div>

    <div className={styles.breakdownsRow}>
      <TopsWidget narrative={narrative} isShare={isShare}/>
    </div>

    <h2>{t('Specific groups')}</h2>

    <div className={styles.breakdownsRow}>
      <StateAffiliated narrative={narrative} isShare={isShare} details={true}/>
    </div>

    <div className={styles.breakdownsRow}>
      <Compromised narrative={narrative} isShare={isShare}  details={true}/>
    </div>

    <div className={styles.breakdownsRow}>
      <Bots narrative={narrative} isShare={isShare} details={true}/>
    </div>

    {isModalOpen ? <NarrativeTreeChartModal
        infoModal={isModalOpen}
        isChart={true}
        platformOption={platformsFilter}
        type={sourcesTypesFilter}
        subType={sourcesSubtypesFilter}
        stateAffiliatedId={affiliationCountryId}
        countryId={countriesFilter}
        // platformsOptions={platformOptions}
        audienceRange={audienceFilter}
        narrative={narrative}
        sourceGroup={sourceGroupFilter}
        source={sourceFilter}
        isShare={isShare}
        onChange={(bool) => {
          closeModal()
        }}
        
      />
      : ''}
    </div>
  )
}

