import { useTranslation } from 'react-i18next';
import { useState, useRef, useContext } from 'react';

import { Collapsible } from './Collapsible';
import { PlatformChooser } from './PlatformChooser'; 
import { SourceGroupsChooser } from './SourceGroupsChooser';
import { ReactComponent as FilterIcon } from '../assets/filter.svg';
import { DoubleRangeSlider } from './DoubleRangeSlider/DoubleRangeSlider';
import { LanguageChooser } from './LanguageChooser';
import { SourceChooser } from './SourceChooser';
import { CountryChooser } from "./CountryChooser";
import { Sentiment } from './Sentiment';
import { ContentTypeChooser } from "./ContentTypeChooser";
import {useClickOutside} from '../../../utils/useClickOutside.jsx'
import './Filter.scss';
import styles from './Filter.module.scss';
import {MessageStatusChooser} from "./MessageStatusChooser";
import {FavoriteChooser} from "../FavoriteChooser";
import { MessagesContext } from '../contexts/MessagesContext';
import { useCurrentUser } from '../../../contexts/CurrentUser';


export const Filter = ({
  isShare,
  platformsFilter, 
  sourceGroupsFilter, 
  languagesFilter, 
  sourcesFilter, 
  countriesFilter, 
  manipulationIndexFilter,
  sentimentFilter,
  contentTypesFilter,
  messagesStatusFilter,
  showOnlyFavoritesFilter,  
  onChange, isThreat=false}) => {
  const { t } = useTranslation();
  const clickRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [platforms, setPlatforms] = useState(platformsFilter || []);
  const [sourceGroups, setSourceGroups] = useState(sourceGroupsFilter || []);
  const [languages, setLanguages] = useState(languagesFilter || []);
  const [sources, setSources] = useState(sourcesFilter || []);
  const [countries, setCountries] = useState(countriesFilter || []);
  const [manIndexRange, setManIndexRange] = useState(manipulationIndexFilter || [0, 1]);
  const [sentiment, setSentiment] = useState(sentimentFilter || []);
  const [contentTypes, setContentTypes] = useState(contentTypesFilter || []);
  const [messageStatuses, setMessagesStatuses] = useState(messagesStatusFilter || []);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(showOnlyFavoritesFilter || false);
  const [isPlatformsOpen, setIsPlatformsOpen] = useState(false);
  const [isSourceGroupsOpen, setIsSourceGroupsOpen] = useState(false);
  const [isManipulationOpen, setIsManipulationOpen] = useState(false);
  const [isLanguagesOpen, setIsLanguagesOpen] = useState(false);
  const [isSourcesOpen, setIsSourcesOpen] = useState(false);
  const [isCountriesOpen, setIsCountriesOpen] = useState(false);
  const [isSentimentOpen, setIsSentimentOpen] = useState(false);
  const [isContentTypesOpen, setIsContentTypesOpen] = useState(false);
  const [isMessagesStatusesOpen, setIsMessagesStatusesOpen] = useState(false);
  const [isShowFavoritesOpen, setIsShowFavoritesOpen] = useState(false);

  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');

  const {
    messages,
    selectedMessages,
    getMessages,
    toggleAll,
    toggleSelected,
    deduplication,
    patchMessages,
    deleteMessages,
    translateMessage,
    toggleTranslate,
    dispatch,
    isLoading,
    setLoading,
  } = useContext(MessagesContext);

  
  let filtersSumm = platforms.length + sourceGroups.length + languages.length + sources.length + countries.length + sentiment.length + contentTypes.length + messageStatuses.length;

  useClickOutside(clickRef, () => {
    if(isActive) {
      setIsActive(false)
    }
  })

  return (
    <div ref={clickRef} className={styles.root}>
      <button 
        className={isActive ? `${styles.button} ${styles.filters} ${styles.active}` : `${styles.button} ${styles.filters}`}
        onClick={() => setIsActive(!isActive)}
      >
        {filtersSumm > 0 ? <span className='summ'>{filtersSumm }</span> : ''}
        {t('Filters')}
        <FilterIcon className={isActive ? 'iconActive' : 'icon'}/>
      </button>

      <div className={isActive ? `${styles.container} ${styles.show}` : `${styles.container}`}>
        <div className={styles.header}>
          <span>{t('Filter by')}</span>

          <span onClick={()=> {
            if (!isPlatformsOpen || !isSourceGroupsOpen || !isLanguagesOpen || !isSourcesOpen || !isManipulationOpen || !isCountriesOpen || !isSentimentOpen || !isContentTypesOpen || !isMessagesStatusesOpen) {
              setIsPlatformsOpen(true);
              setIsSourceGroupsOpen(true);
              setIsManipulationOpen(true);
              setIsLanguagesOpen(true);
              setIsSourcesOpen(true);
              setIsCountriesOpen(true);
              setIsSentimentOpen(true);
              setIsContentTypesOpen(true);
              setIsMessagesStatusesOpen(true);
            } else {
              setIsPlatformsOpen(false);
              setIsSourceGroupsOpen(false);
              setIsManipulationOpen(false);
              setIsLanguagesOpen(false);
              setIsSourcesOpen(false);
              setIsCountriesOpen(false);
              setIsSentimentOpen(false);
              setIsContentTypesOpen(false);
              setIsMessagesStatusesOpen(false);
            }
          }}>
            {isPlatformsOpen && isSourceGroupsOpen && isManipulationOpen && isLanguagesOpen && isSourcesOpen && isCountriesOpen && isSentimentOpen && isContentTypesOpen && isMessagesStatusesOpen ? `${t('Minimize all')}` : `${t('Expand all')}`}
          </span>
        </div>

        <div className={styles.collapsiblesWrapper}>

        { isThreat || deduplication ? '' : <Collapsible
            name='Favorite messages'
            isOpen={isShowFavoritesOpen}
            onChange={(open) => setIsShowFavoritesOpen(open)}
          >
            <FavoriteChooser
              checked={showOnlyFavorites}
              onChange={(value) => setShowOnlyFavorites(value)}
            />
          </Collapsible> }

          { isThreat && !currentUser?.is_super_admin ? '' : <Collapsible
            name='Messages status'
            isOpen={isMessagesStatusesOpen}
            summ={messageStatuses.length}
            onChange={(open) => setIsMessagesStatusesOpen(open)}
          >
            <MessageStatusChooser
              selected={messageStatuses}
              onChange={(checklist) => setMessagesStatuses(checklist)}
            />
          </Collapsible> }
          
          {deduplication ? '' : <Collapsible
            name='Content types'
            summ={contentTypes.length}
            isOpen={isContentTypesOpen}
            onChange={(open) => setIsContentTypesOpen(open)}
          >
            <ContentTypeChooser
              selected={contentTypes}
              onChange={(checklist) => setContentTypes(checklist)}
            />
          </Collapsible>}

          {deduplication ? '' : <Collapsible
            name='Language'
            summ={languages.length}
            isOpen={isLanguagesOpen}
            onChange={(open) => setIsLanguagesOpen(open)}
          >
            <LanguageChooser
              selected={languages}
              onChange={(checklist) => setLanguages(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>}

          {deduplication ? '' : <Collapsible 
            name='Platform'
            summ={platforms.length}
            isOpen={isPlatformsOpen}
            onChange={(open) => setIsPlatformsOpen(open)}
          >
            <PlatformChooser 
              selected={platforms}
              onChange={(checklist) => setPlatforms(checklist)}
            />
          </Collapsible>}

          {deduplication ? '' : <Collapsible
            name='Country'
            summ={countries.length}
            isOpen={isCountriesOpen}
            onChange={(open) => setIsCountriesOpen(open)}
          >
            <CountryChooser
              selected={countries}
              onChange={(checklist) => setCountries(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>}

          {deduplication || isShare ? '' : <Collapsible 
            name='Actor group' 
            summ={sourceGroups.length}
            isOpen={isSourceGroupsOpen}
            onChange={(open) => setIsSourceGroupsOpen(open)}
          >
            <SourceGroupsChooser 
              selected={sourceGroups}
              onChange={(checklist) => setSourceGroups(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>}

          {deduplication ? '' : <Collapsible
            name='Actor'
            summ={sources.length}
            isOpen={isSourcesOpen}
            onChange={(open) => setIsSourcesOpen(open)}
          >
            <SourceChooser
              selected={sources}
              onChange={(checklist) => setSources(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>}

          {deduplication || !showMessageManipulationIndex ? '' : <Collapsible 
            name='Manipulation index'
            isOpen={isManipulationOpen}
            onChange={(open) => setIsManipulationOpen(open)}
          >
            <DoubleRangeSlider
              min={0}
              max={1}
              step={0.1}
              manIndexRange={manIndexRange}
              onChange={(range) => setManIndexRange(range)}

            />
          </Collapsible>}

          {deduplication ? '' : <Collapsible 
            name='Sentiment'
            isOpen={isSentimentOpen}
            onChange={(open) => setIsSentimentOpen(open)}
          >
            <Sentiment
              selected={sentiment}
              onChange={(checklist) => setSentiment(checklist)}
            />
          </Collapsible>}

          {/*  nest filter order: sentiment, persons, locations, organizations */}
        </div>

        <div className={styles.controls}>
          <button 
            className={`${styles.button} ${styles.apply}`}
            onClick={() => {
              onChange(
                platforms,
                sourceGroups.map(group => group.value),
                manIndexRange,
                languages.map(lang => lang.value),
                sources.map(source => source.value),
                countries.map(country => country.value),
                sentiment,
                contentTypes,
                messageStatuses,
                showOnlyFavorites
              )
              setIsActive(false)
            }}
          >
            {t('Apply')}
          </button>

          <button 
            className={`${styles.button} ${styles.reset}`}
            onClick={() => {
              setPlatforms([]);
              setSourceGroups([]);
              setManIndexRange([0,1]);
              setLanguages([]);
              setSources([]);
              setCountries([]);
              setSentiment([]);
              setContentTypes([]);
              setMessagesStatuses([]);
              setShowOnlyFavorites(false);
            }}
          >
            {t('Reset all')}
          </button>
        </div>
      </div>


    </div>
  )
}
