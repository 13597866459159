import { format } from 'date-fns';
import { ampli } from '../../ampli';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { API, ShareAPI } from "../../API";
import { useTranslation } from "react-i18next";
import { LoaderSmall } from "../LoaderSmall/LoaderSmall";
import { NarrativeTreeChartModal } from '../../pages/NarrativePage/TreeChartModal/NarrativeTreeChartModal';

import styles from './ActorsTypes.module.scss';

const platformsData = {
  "1": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 878 },
      { "bucket": "TELEGRAM", "count": 57 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "2": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 55 },
      { "bucket": "TELEGRAM", "count": 7 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 2 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "3": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 0 },
      { "bucket": "TELEGRAM", "count": 65 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "4": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 14 },
      { "bucket": "TELEGRAM", "count": 24 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "5": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 106 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "6": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 192 },
      { "bucket": "TELEGRAM", "count": 17 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "7": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 61 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "8": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 56 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "9": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 43 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "10": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 43 },
      { "bucket": "TELEGRAM", "count": 470 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "11": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 28 },
      { "bucket": "TELEGRAM", "count": 30 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "12": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 7 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  }
}




export const PlatformsChart = ({
  report,
  story,
  isShare,
  isClickable=true,
  sourceGroups,
  countries,
  startDate,
  endDate
}) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [platforms, setPlatforms] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState(null);
  const api = isShare ? ShareAPI : API;

  const bucketNameMap = {
    'FACEBOOK': t('Facebook'),
    'TWITTER': t('Twitter'),
    'TELEGRAM': t('Telegram'),
    'YOUTUBE': t('Youtube'),
    'WEB': t('Web'),
    'TIKTOK': t('TikTok'),
    'VK': t('VK'),
  }

  const getPlatforms = () => {
    if (!story) return;
    const urlParams = new URLSearchParams();
    urlParams.append('breakdown', 'platform');
    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    api.fetch('GET', `/API/v1/topic-modeling/${report?.id}/events/${story?.id}/sources/breakdown?${urlParams.toString()}`).then(
      data => setPlatforms(data)
    )
    // setPlatforms(platformsData[story.id])
  }

  useEffect(() => {
    getPlatforms()
  }, [story])

  // const colors = ['#BEFDEE', '#7FFADD', '#45F1C9', '#1CD6AA', '#00BA8D'];
  const colors = ['#3b82f6'];

  const getChartData = (platforms) => {
    let data = [
      ['Platform', 'Number', { role: "style" }, { role: 'annotation' }]
    ];
    
    platforms.objects?.forEach((platform, index) => 
       data.push([bucketNameMap[platform.platform], platform.sources_count, '#3b82f6', platform.sources_count])
    )
    
    return data;
  }

  const options = {
    chartArea: { left: '5%', top: '15%', width:'100%', height:'75%' },
    legend: 'none',
    bar: { groupWidth: "55%" },
    annotations: {alwaysOutside: true, stem: {color: 'transparent'}, textStyle: {bold: true, opacity: 1, color: '#151419'}, highContrast: false}
  }

  return (
    <>
      {platforms ? (
        platforms.objects?.length > 0 ? (
          <div className={styles.chartWrapper}>
            <Chart
              chartType="ColumnChart"
              data={getChartData(platforms)}
              width={'100%'}
              height={'400px'}
              className="barChart"
              options={options}
              chartEvents={
                isClickable
                  ? [
                      {
                        eventName: 'select',
                        callback({ chartWrapper }) {
                          let selected = chartWrapper.getChart().getSelection();
                          let index = selected[0]?.row;
                          let bucket = platforms.objects[index].platform;
                          setFilter(bucket);
                          setIsModalOpen(true);
                          ampli.track({
                            event_type: 'Open wiget popup in event',
                            event_properties: {
                              user_id: currentUser?.id,
                              workspace_id: currentUser?.workspace_id,
                              report_id: report?.id,
                              popup_type: 'Actor Types: Platforms',
                              popup_value: 'Platform: ' + bucketNameMap[bucket],
                            }
                          });
                        },
                      },
                    ]
                  : []
              }
              style={{ margin: '0px', padding: '0px' }}
              chartVersion="51"
            />
          </div>
        ) : (
          <div className={styles.noData}>
            {t('There is no data to display')}
          </div>
        )
      ) : (
        <div className={styles.widdgetLoader}>
          <LoaderSmall />
        </div>
      )}

      {isModalOpen ? (
        <NarrativeTreeChartModal
          infoModal={isModalOpen}
          isChart={true}
          platformOption={{ value: filter, label: bucketNameMap[filter] }}
          type={null}
          subType={null}
          stateAffiliatedId={null}
          countryId={countries?.length > 0 ? countries : null}
          // platformsOptions={platformOptions}
          audienceRange={null}
          narrative={report}
          sourceGroup={
            sourceGroups.length > 0
              ? sourceGroups?.map((sourceGroup) => sourceGroup.value)
              : null
          }
          source={null}
          sentimentOptions={null}
          eventIdsOptions={[story.id]}
          startDate={startDate}
          endDate={endDate}
          contentTypeOptions={null}
          isShare={isShare}
          onChange={(bool) => {
            setIsModalOpen(bool);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};