import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { API } from '../../API';
import { Loader } from '../../components/Loader/Loader';
import { SourcesTable } from '../../components/SourcesTable/SourcesTable';
import { CheckboxField } from '../../components/Checkbox/CheckboxField';
import styles from './CreateGroupPage.module.scss';
import Switch from "../../components/Switch";
import {useCurrentUser} from "../../contexts/CurrentUser";

const TEMP_GROUP_ID = 'TEMP_GROUP_ID';

export const CreateGroupPage = () => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const inputNameRef = useRef(null);
  const formRef = useRef(null);

  const [sources, setSources] = useState(null);
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');

  const fetchSources = useCallback(() => {
    setSources(null);
    const urlParams = new URLSearchParams();
    urlParams.set('size', '10');
    urlParams.set('page', page);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    API.fetch('GET', `/API/v1/sources?${urlParams.toString()}`).then(
      setSources
    );
  }, [page, searchQuery]);

  useEffect(fetchSources, [fetchSources]);

  const [selectedSourceIds, setSelectedSourceIds] = useState(new Set());

  const impureSources = useMemo(() => {
    if (!sources) {
      return null;
    }

    return {
      ...sources,
      objects: sources.objects.map((source) => ({
        ...source,
        groups: selectedSourceIds.has(source.id)
          ? [...source.groups, TEMP_GROUP_ID]
          : source.groups,
      })),
    };
  }, [sources, selectedSourceIds]);

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>
              <Link to="/groups">{t('Groups')}</Link>
            </span>
            <span>{t('New')}</span>
          </div>
        </div>
      </div>

      <div className="form-wrapper">
        <form
          ref={formRef}
          onSubmit={async (e) => {
            e.preventDefault();

            const { id } = await API.fetch('POST', `/API/v1/groups`, null, {
              name, is_public: isPublic,
            });

            await API.fetch('POST', `/API/v1/groups/${id}/sources`, null, {
              source_ids: [...selectedSourceIds],
            }).then(() => {
              navigate('/groups');
            });
          }}
        >
          <div className="form-element">
            <label className={styles.label} htmlFor="name">
              {t('Name')}
            </label>
            <input
              ref={inputNameRef}
              type="text"
              id="name"
              name="name"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
              autoFocus={true}
            />
          </div>

          { currentUser?.is_super_admin && (
            <div className="form-element">
              <label className={styles.label} htmlFor="is_public">{t('Is public')}</label>
              <Switch
                id="is_public"
                value={isPublic}
                onChange={setIsPublic}
              />
            </div> )}

          <div className="form-element">
            {impureSources ? (
              <SourcesTable
                headerClassName={styles.tableHeader}
                rowClassName={styles.tableRow}
                sources={impureSources}
                withSearch={true}
                searchQuery={searchQuery}
                checkIsSelected={(source) => selectedSourceIds.has(source.id)}
                renderPrefixRow={(source) => (
                  <CheckboxField
                    className={styles.tableRow}
                    checked={selectedSourceIds.has(source.id)}
                    onChange={() => {
                      setSelectedSourceIds((prev) => {
                        if (prev.has(source.id)) {
                          prev.delete(source.id);
                        } else {
                          prev.add(source.id);
                        }

                        return new Set(prev);
                      });
                    }}
                  />
                )}
              />
            ) : (
              <Loader />
            )}
          </div>

          <div className="form-element">
            <button
              type="submit"
              className='new-button'
              onClick={() => {
                const formValid = formRef.current.reportValidity();

                if (!formValid) {
                  requestAnimationFrame(() => {
                    window.scroll({
                      top: 0,
                      behavior: 'smooth',
                    });
                  });
                }
              }}
            >
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
