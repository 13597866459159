import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import React from "react";
import { AuthProvider, RequireAuth } from './contexts/Auth';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { ShareLoginPage } from './pages/ShareLoginPage/ShareLoginPage';
import { ReportsPage } from './pages/ReportsPage/ReportsPage';
import { ReportPage } from './pages/ReportPage/ReportPage';
import { TopicReportsPage } from './pages/TopicReportsPage/TopicReportsPage';
import { TopicReportPage } from './pages/TopicReportPage/TopicReportPage';
import { ReportAddPage } from './pages/ReportAddPage/ReportAddPage';
import { ReportEditPage } from './pages/ReportEditPage/ReportEditPage';
import { NarrativesPage } from './pages/NarrativesPage/NarrativesPage';
import { NarrativesModerationPage } from './pages/NarrativesModerationPage/NarrativesModerationPage';
import { NarrativeEditHistoryPage } from './pages/NarrativeEditHistoryPage/NarrativeEditHistoryPage';
import { UsersPage } from './pages/UsersPage/UsersPage';
import { UserAddPage } from './pages/UserAddPage/UserAddPage';
import { UserEditPage } from './pages/UserEditPage/UserEditPage';
// import { NarrativePage } from './pages/NarrativePage/NarrativePage';
import { NarrativePageWrapper } from './pages/NarrativePage/NarrativePageWrapper';
import NarrativeAddPage from './pages/NarrativeAddPage/NarrativeAddPage';
import ThreatAddPage from './pages/ThreatAddPage/ThreatAddPage';
import ThreatEditPage from './pages/ThreatEditPage/ThreatEditPage';
import NarrativeEditPage from './pages/NarrativeEditPage/NarrativeEditPage';
import { SourcesPage } from './pages/SourcesPage/SourcesPage';
import { WorkspacesPage } from './pages/WorkspacesPage/WorkspacesPage';
import { UserStatsPage } from './pages/UserStatsPage/UserStatsPage';
import { Layout } from './pages/Layout/Layout';
import { LayoutShare } from './pages/LayoutShare/LayoutShare';
import { CurrentUserProvider } from './contexts/CurrentUser';
import { GroupsPage } from './pages/GroupsPage/GroupsPage';
import { GroupPage } from './pages/GroupPage/GroupPage';
import { CreateGroupPage } from './pages/CreateGroupPage/CreateGroupPage';
import { SearchPage } from './pages/SearchPage/SearchPage';
import { SourcePage } from './pages/SourcePage/SourcePage';
import { ThreatsPageWrapper } from './pages/ThreatsPage/ThreatsPageWrapper';
import { UserSettingsPageWrapper } from "./pages/UserSettingsPage/UserSettingsPageWrapper";
import { SourceEditPage } from "./pages/SourceEditPage/SourceEditPage";
import { PowerBIDashboardsPage } from "./pages/PowerBIDashboardsPage/PowerBIDashboardsPage";
import { PowerBIDashboardPage } from "./pages/PowerBIDashboardPage/PowerBIDashboardPage";
import { ExportPDFPageWrapper } from "./pages/NarrativePage/ExportPDF/ExportPDFPageWrapper";
import { ExportPDFReportPage } from "./pages/ReportPage/ExportPDF/ExportPDFReportPage";
import { TopicReportPageNew } from "./pages/TopicReportPage/TopicReportPageNew";
import { TopicReportsLatest } from "./pages/TopicReportsPage/TopicReportsLatest";
import { HomePage } from './pages/HomePage/HomePage';
import * as Sentry from "@sentry/react";
import './App.scss';
import { session_id } from './utils/session_id';

import { ampli, DefaultConfiguration } from "./ampli";
import {ExportsPage} from "./pages/ExportsPage/ExportsPage";

const { REACT_APP_AMPLITUDE_API_KEY = "9739dba3d12a3c19b2c32312c6002128" } = process.env;

ampli.load({
  disabled: !['app.osavul.cloud', 'staging.osavul.cloud', 'sandbox.osavul.cloud', 'localhost'].includes(window.location.hostname),
  client: {
    apiKey: ['staging.osavul.cloud', 'sandbox.osavul.cloud', 'localhost'].includes(window.location.hostname) ? '48b13a07483bea3196faf45c3b8d27b8' : REACT_APP_AMPLITUDE_API_KEY,
    configuration: { ...DefaultConfiguration, logLevel: 3 },
  },
});


let sentry_dsn = 'https://examplePublicKey@o0.ingest.sentry.io/0'

if (window.location.hostname === 'app.osavul.cloud') {
  sentry_dsn = "https://6205128ec228c9c9d48442bd75bd8109@o4505516807421952.ingest.sentry.io/4505792823296000";
}

Sentry.init({
  dsn:  sentry_dsn,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app.osavul.cloud\/API/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  initialScope: {
    tags: {
        transaction_id: session_id,
    }
  },

  ignoreErrors: [
      /.*Client error:.*/,
      /.*TypeError: Load failed.*/,
      /.*signal is aborted.*/,
      /.*Fetch is aborted.*/,
      /.*NetworkError when attempting to fetch resource.*/,
      /.*Failed to fetch.*/,
      /.*скасовано.*/,
      /.*the operation was aborted.*/,
      /.*No error message.*/,
      /.*The operation was aborted.*/,
  ],
})


const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {

  return (
    <AuthProvider>
      <CurrentUserProvider>
        <BrowserRouter>
          <SentryRoutes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/reports/:reportId/share/login"
              element={<ShareLoginPage />}
            />
            <Route
              path="/narratives/:narrativeId/share/login"
              element={<ShareLoginPage />}
            />

            <Route element={<LayoutShare />}>
              <Route
                path="/reports/:reportId/share"
                element={<ReportPage isShare={true} />}
              />
            </Route>
            <Route element={<LayoutShare />}>
              <Route
                path="/narratives/:narrativeId/share"
                element={<NarrativePageWrapper isShare={true} />}
              />
            </Route>
            <Route
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route path="/" element={<HomePage />} />
              <Route path="/reports" element={<ReportsPage />} />
              <Route path="/reports/:reportId" element={<ReportPage />} />
              <Route
                path="/reports/:reportId/edit"
                element={<ReportEditPage />}
              />
              <Route
                path="/reports/:reportId/exportPDF"
                element={<ExportPDFReportPage />} />
                
              <Route path="/reports/new" element={<ReportAddPage />} />
              <Route path="/topic_reports" element={<TopicReportsPage />} />
              <Route path="/topic_reports/latest" element={<TopicReportsLatest />} />
              <Route path="/topic_reports/:topicReportId" element={<TopicReportPageNew />} />
              <Route path="/narratives" element={<NarrativesPage />} />
              <Route path="/exports" element={<ExportsPage />} />
              <Route
                path="/narratives/:narrativeId"
                element={<NarrativePageWrapper />}
              />
              <Route
                path="/narratives/:narrativeId/edit/history"
                element={<NarrativeEditHistoryPage />}
              />
              <Route
                path="/narratives/:narrativeId/edit"
                element={<NarrativeEditPage />}
              />

              <Route
                path="/narratives/:narrativeId/exportPDF"
                element={<ExportPDFPageWrapper />}
              />

              <Route path="/narratives_moderation" element={<NarrativesModerationPage />} />
              <Route path="/narratives/new" element={<NarrativeAddPage />} />
              <Route path="/sources" element={<SourcesPage />} />
              <Route path='/dashboards' element={<PowerBIDashboardsPage/>}/>
              <Route path='/dashboards/:dashboardId' element={<PowerBIDashboardPage/>}/>
              <Route path="/sources/:sourceId/edit" element={<SourceEditPage/>}/>
              <Route path="/sources/:sourceId" element={<SourcePage />}/>
              <Route path="/search" element={<SearchPage/>} />
              <Route path="/threats" element={<ThreatsPageWrapper/>} />
              <Route path="/threats/:threatId/edit" element={<ThreatEditPage />} />
              <Route
                path="/threats/:narrativeId/edit/full"
                element={<NarrativeEditPage isThreat={true} />}
              />
              <Route path="/threats/:threatId" element={<NarrativePageWrapper />} />
              <Route path="/threats/new" element={<ThreatAddPage />} />
              <Route path="/threats/narrative/:narrativeID" element={<ThreatsPageWrapper />} />
              <Route path="/groups" element={<GroupsPage />} />
              <Route path="/groups/:groupId" element={<GroupPage />} />
              <Route path="/groups/new" element={<CreateGroupPage />} />
              <Route path="/workspaces" element={<WorkspacesPage />} />
              <Route path="/user_stats" element={<UserStatsPage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/users/:userId/edit" element={<UserEditPage />} />
              <Route path="/users/new" element={<UserAddPage />} />
              <Route path="/users/settings" element={<UserSettingsPageWrapper/>}/>
            </Route>
        </SentryRoutes>
        </BrowserRouter>
      </CurrentUserProvider>
    </AuthProvider>
  );
}

export default App;
