import cn from 'classnames';
import { useCallback, useEffect, useState, useContext, useRef } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MessagesContext } from './contexts/MessagesContext';
import { ThreatEditModal } from '../ThreatsPage/ThreatEditModal';
import streamSaver from 'streamsaver';

import { ampli } from "../../ampli";
import { API, ShareAPI } from '../../API';
import Modal from '../../components/Modal';
import { Loader } from '../../components/Loader/Loader';
import Tooltip from '../../components/Tooltip/Tooltip';
import ExportReportPopup from '../../components/ExportReportPopup';
import { useCurrentUser } from '../../contexts/CurrentUser';
import CopyNarrativeToWorkspacePopup from '../../components/CopyNarrativeToWorkspacePopup';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from './assets/arrowDown.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as ApproveIcon } from './assets/approve.svg';
import { ReactComponent as MailIcon } from './assets/mail.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as ShareIcon } from '../ReportPage/assets/share.svg';
import { ReactComponent as MessagesIcon } from './assets/messages.svg';
import { ReactComponent as ReportIcon } from './assets/report.svg';
import { ReactComponent as SaveIcon} from '../../assets/save.svg';
import { ReactComponent as HistoryIcon } from './assets/history.svg';
import { ReactComponent as ChevronDown} from '../SearchPage/assets/chevron-down.svg';
import { ReactComponent as SourcesAnalysisIcon } from './assets/sources_analysis.svg';
import { ReactComponent as StoriesIcon } from './assets/stories.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as TableIcon } from '../../assets/Table.svg';
import { ReactComponent as BlocksIcon } from '../../assets/Blocks.svg';
import { ReactComponent as MergeIcon } from './assets/merge.svg';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { ReactComponent as DistributionIcon } from '../../assets/distribution.svg';
import { ReactComponent as ShareThreatIcon } from '../../assets/shareThreat.svg';
import { ReactComponent as AddToIcon } from '../../assets/addTo.svg';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import {ReactComponent as LinkIcon} from '../../assets/link.svg';
import { AdjustmentSuggestions } from './AdjustmentSuggestion/AdjustmentSuggestions';
import { Tabs } from '../../components/Tabs/Tabs';
import { NarrativeTreeChart } from './NarrativeTreeChart';
import { NarrativeStories } from './NarrativeStories/NarrativeStories';
import { Distribution } from './Distribution';
import {ExtendedMessageView} from '../../components/MessagesTable/ExtendedMessageView';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { NarrativeEditHistory } from '../NarrativeEditHistoryPage/NarrativeEditHistory';
import { Filter } from './Filter/Filter';
import styles from './NarrativePage.module.scss';
import {QueryInput} from "../NarrativeAddPage/QueryInput/QueryInput";
import {Calendar} from "../SearchPage/Calendar/Calendar";
import { AddToNarrativeModal } from '../../components/AddToNarrativeModal/AddToNarrativeModal';
import { BreakdownsWrapper } from './Breakdowns/BreakdownsWrapper';
import { cutString } from '../../utils/cutString';
import websocketConnection from '../../websocket';
import { CaseStatusbar } from './CaseStatusBar';
import { extractPlatformName } from '../../utils/extractPlatformName';
import { AddToSourceGroupModal } from '../../components/AddToSourceGroupModal/AddToSourceGroupModal';
import {toast} from "react-toastify";
import {LoaderSmall} from "../../components/LoaderSmall/LoaderSmall";
import { StoryContent } from '../../components/StoryContent/StoryContent';
import { contentTypesMapping } from '../../utils/contentTypes';
import { mapThreatsStatus, mapThreatsTypesConst, mapThreatsSubjects } from '../../utils/threats';
import { TopicReportPageNew } from '../TopicReportPage/TopicReportPageNew';

const sortingFieldNames = {
  DATE: 'date_publicated',
  // SOURCE: 'source',
  // MESSAGE: 'text',
  VIEWS: 'impressions',
  REACTIONS: 'engagement',
  MANIPULATION: 'manipulation_index',
  RELEVANCE: 'relevance',

  AGE: 'user_age',
  LOCATION: 'user_location',
  GROUPS: 'user_groups',
  DEDUPLICATION: 'messages_count',
  FIRST_DATE: 'first_date',
  LAST_DATE: 'last_date',
  ENGAGEMENT_SUM: 'engagement_sum',
};

const mapThreatStatusClasses = {
  ACCEPTED: styles.accepted,
  PENDING: styles.pending,
  DECLINED: styles.declined,
};

export function NarrativePage({ isShare = false }) {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const {
    narrative,
    messages,
    selectedMessages,
    deleteMessages,
    patchMessages,
    deduplication,
    toggleDeduplication,
    clearSelectedMessages,
    toggleTab,
    clearAllSelectedMessages,
    applySearch,
    getMessages,
    dispatch,
    fetchNarrative,
    getStories,
    error
  } = useContext(MessagesContext);

  const initStartDate = null;
  const initEndDate = null;

  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const isAdvancedSyntax = workspaceConfig?.keywords_parser_class === 'Workspace16ESKeywordsQuery';
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');
  const addToActorGroup = featureFlags?.includes('add_to_actor_group');
  const doNotShowCommentsByDefault = featureFlags?.includes('do_not_show_comments_by_default');

  const defaultContentTypes = doNotShowCommentsByDefault ? Object.keys(contentTypesMapping).filter(key => key !== 'COMMENT') : [];

  // Search block
  const [activeLoadMoreButton, setActiveLoadMoreButton] = useState(false);
  const [exportModal, setExportModal] = useState({ isActive: false, id: null });
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [sentiment, setSentiment] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [sourceGroups, setSourceGroups] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [contentTypes, setContentTypes] = useState(defaultContentTypes);
  const [countries, setCountries] = useState([]);
  const [cursor, setCursor] = useState(null);
  const [sources, setSources] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [manipulationIndex, setManipulationIndex] = useState([]);
  const queryInputRef = useRef(null);
  const keywordsRef = useRef(null);
  const [isValidQuery, setIsValidQuery] = useState(true);
  const onKeywordsChange = useCallback((value, isValid) => {
    keywordsRef.current = value;
    setIsValidQuery(isValid);

  }, []);

  useEffect(() => {
    if(searchQuery !== null && searchQuery?.trim().length > 0) {
      applySearch(true)
    } else if (searchQuery?.trim().length === 0) {
      applySearch(false)
    }
  }, [searchQuery])

  const api = isShare ? ShareAPI : API;

  const [messagesStatus, setMessagesStatus] = useState(null);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);

  const [threatName, setThreatName] = useState(null);
  const [threatDescription, setThreatDescription] = useState(null);
  const [incidentType, setIncidentType] = useState(null);
  const [topics, setTopics] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [messageModeIndex, setMessageModeIndex] = useState(1);
  const selectedMessagesKeys = Object.keys(selectedMessages);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isActive: false,
    narrative: null,
    status: null,
    ids: null,
  });
  const [approveConfirmModal, setApproveConfirmModal] = useState({
    isActive: false,
    narrative: null,
    status: null,
    ids: null,
  });
  const [copyNarrativeModal, setCopyNarrativeModal] = useState({
    isActive: false,
    workspaceId: null,
  });

  const [saveAsNarrativeModal, setSaveAsNarrativeModal] = useState({isOpen: false, threat: narrative})
  const [saveAsThreatModal, setSaveAsThreatModal] = useState({isOpen: false, narrative: narrative});

  const navigate = useNavigate();
  const { narrativeId, threatId } = useParams();
  const [searchParams] = useSearchParams();
  const [sorting, setSorting] = useState({isAscending: false, fieldName: 'DATE'});
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);
  const [isNarrativeValid, setIsNarrativeValid] = useState({fiveMessages: false});
  const [errorModal, setErrorModal] = useState(false);
  const [editThreatModal, setEditThreatModal] = useState({isOpen: false, threat: null});
  const [addToNarrativeModal, setAddToNarrativeModal] = useState(false);
  const [addToThreatModal, setAddToThreatModal] = useState(false);
  const [addToSourceGroupMadal, setAddToSourceGroupModal] = useState(false);
  const [mergeStoriesModal, setMergeStoriesModal] = useState({
    isActive: false,
    story_ids: [],
    new_summary: '',
    isLoading: false,
    error: null,
  });

  const sortingQuery = sorting.fieldName
    ? `${sorting.isAscending ? '' : '-'}${sortingFieldNames[sorting.fieldName]}`
    : null;

  const [page, setPage] = useState(1);
  const [narrativeStatus, setNarrativeStatus] = useState(narrative?.impact_assessment_processing_metadata?.status);

  useEffect(() => {
    if (narrative?.type !== 'IMPACT_ASSESSMENT') {
      return;
    }

    const handleImpactAssessmentProgress = (data) => {
      if (data?.object_id !== narrative?.id) {
        return;
      }

      try {
        setNarrativeStatus(data?.data.status)

        if(data?.data.status === 'DONE') {
          let id = narrativeId || threatId;
          fetchNarrative(id)
        }
      } catch {}
    };

    websocketConnection.addHandler('IMPACT_ASSESSMENT', handleImpactAssessmentProgress);
  }, [narrative]);

  useEffect(() => {
    let id = narrativeId || threatId;
    fetchNarrative(id)
  }, [narrativeId, threatId])

  useEffect(() => {
    if(narrative?.type !== "IMPACT_ASSESSMENT") {
      return
    }
    setNarrativeStatus(narrative?.impact_assessment_processing_metadata?.status)
  }, [narrative])


  const buttonsOnScroll = () => {
    if(Object.keys(selectedMessages).length > 0) {
      const buttons = document.getElementById('bulk');
      let scrolly = window.pageYOffset;
      if (!buttons) {
        return
      }

      if(scrolly > 150) {
        buttons.classList.add(styles.floating)
      } else {
        buttons.classList.remove(styles.floating)
      }
    } else {
      return
    }
  }

  useEffect(() => {
      window.addEventListener('scroll', buttonsOnScroll);
      window.addEventListener('mousemove', buttonsOnScroll);

    return() => {
      window.removeEventListener('scroll', buttonsOnScroll);
      window.removeEventListener('mousemove', buttonsOnScroll);
    }
  })
   

  const saveFile = (blob, format) => {
    console.log(blob);
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${narrative.name}_${new Date().toISOString()}.${format}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const handleExport = (format, narrativeId, sortingQuery, searchQuery) => {
    const urlParams = new URLSearchParams();
    urlParams.set('narrative_id', narrativeId);

    if (narrative.parameters?.start_date) {
      urlParams.set(
        'start',
        narrative.parameters.start_date.split('T')[0] + 'T00:00:00',
      );
    }
    if (narrative.parameters?.end_date) {
      urlParams.set(
        'end',
        narrative.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    if (sortingQuery) {
      urlParams.set('sorting', sortingQuery);
    }

    ampli.track({
      event_type: 'Export narrative',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        narrative_id: narrativeId,
        format: format,
      }
    });

    api.fetchBlob(
      'GET',
      `/API/v1/messages/export/${format}?${urlParams.toString()}`,
    ).then((blob) => {
      saveFile(blob, format);
    });
  };

  const handleAsyncExport = (format, narrativeId) => {
    API.fetch('POST', `/API/v1/async-export`, null, {
      'type': 'case',
      'object_id': narrativeId,
      'output_format': format,
    }).then((data) => {
      const Msg = () => (
        <div>
          {t('An export of') + ` ${t('export_type_of_' + data.type)} id=${data.object_id} `}
          <Link to={`/exports?object_id=${data.object_id}&types=${data.type}`}>
            {t(`export_status_${data.status}`).toLowerCase()}
          </Link>
        </div>
      );

      const displayMsg = () => {
        toast.success(<Msg />);
      };
      displayMsg();
    });
  };

  const handleExportStream = async (format, narrativeId, sortingQuery, searchQuery) => {
    const urlParams = new URLSearchParams();
    urlParams.set('narrative_id', narrativeId);

    if (narrative.parameters?.start_date) {
      urlParams.set(
        'start',
        narrative.parameters.start_date.split('T')[0] + 'T00:00:00',
      );
    }
    if (narrative.parameters?.end_date) {
      urlParams.set(
        'end',
        narrative.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    if (sortingQuery) {
      urlParams.set('sorting', sortingQuery);
    }

    try {
      const response = await api.Fetch(
        'GET',
        `/API/v1/messages/export/${format}?${urlParams.toString()}`,
      )

      const filename = `${narrative.name}_${new Date().toISOString()}.${format}`;

      if (!response.ok) {
        throw new Error(`Failed to download the file. Status: ${response.status} - ${response.statusText}`);
      }

      const contentLength = response.headers.get('Content-Length');

      const fileStream = streamSaver.createWriteStream(filename, {
        size: parseInt(contentLength),
      });

      const writer = fileStream.getWriter();

      const reader = response.body.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          writer.close();
          break;
        }
        writer.write(value);
      }

      console.log('File download completed successfully!');
    } catch (error) {
      console.error('Failed to download the file:', error);
    }
  };

  useEffect(() => {
    if (isShare) {
      return
    }

    if(narrative) {
      if(narrative?.is_threat && narrative?.review_status === 'ACCEPTED' && (narrative.read === false || narrative.read === null)) {
        api.fetch('POST', `/API/v1/threats/${narrative.id}/read/`, null).then((data) => {
          return
        });
      }
    } else {
      return
    }

    if (narrative.is_threat && narrative.review_status === 'ACCEPTED') {
      ampli.track({
        event_type: 'Open threat',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          threat_id: narrative.id,
        }
      });
    }

  }, [narrative])

  const checkNarrative = useCallback(() => {
    if (!narrative) {
      return;
    }

    let isFiveMessages = false;

    const urlParams = new URLSearchParams();
    urlParams.set('narrative_id', narrative.id);
    urlParams.set('message_status', 'READ');
    urlParams.set('size', 5);

    api.fetch('GET', `/API/v1/messages?${urlParams.toString()}`).then(
      (data) => {
        if(data.total >= 3 ) {
          isFiveMessages = true;
        }

        setIsNarrativeValid({fiveMessages: isFiveMessages})
      },
    );
  }, [narrative]);
  
  useEffect(() => {
    checkNarrative();
  }, [narrative, messages]);


  const sortingMenu = [
    {
      name: t('Date'),
      value: 'DATE',
    },
    {
      name: t('Relevance'),
      value: 'RELEVANCE',
    },
    // {
    //   name: t('Actor'),
    //   value: 'SOURCE',
    // },
    // {
    //   name: t('Text'),
    //   value: 'MESSAGE',
    // },
    {
      name: t('Views'),
      value: 'VIEWS',
    },
    {
      name: t('Reactions'),
      value: 'REACTIONS',
    },
    
  ];

  if (showMessageManipulationIndex) {
    sortingMenu.push({
      name: t('Manipulation'),
      value: 'MANIPULATION',
    },)
  }

  const sortingMenuStories = [
    {
      name: t('Stories'), 
      value: 'DEDUPLICATION'
    },
    {
      name: t('First message'),
      value: 'FIRST_DATE',
    },
    {
      name: t('Last message'),
      value: 'LAST_DATE',
    },
    {
      name: t('Reactions'),
      value: 'ENGAGEMENT_SUM',
    },
  ]

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  
    // if(deduplication) {
    //   sortingMenu.unshift({name: t('Stories'), value: 'DEDUPLICATION'})
    // } else if (!deduplication && sortingMenu[0].value === 'DEDUPLICATION') {
    //   sortingMenu.shift()
    // } 
  

  if(!narrative && error) {
    return <div className='noData'>{t(error)}</div>
  }

  if (!narrative) {
    return <Loader />;
  }


  const searchPanel = <div className={'narrative-search-form-wrapper ' + styles.searchWrapper}>
      <QueryInput
        isAdvanced={isAdvancedSyntax}
        search={true}
        ref={queryInputRef}
        className={styles.queryInput}
        onChange={onKeywordsChange}
        isInvalid={!isValidQuery}
        clear={() => {
          keywordsRef.current = null;
          setSearchQuery(null);
          setCursor(null)
          setPage(1)
          applySearch(false)
        }}
        onKeyDown={(event) => {
          if (isValidQuery && event.key === 'Enter' && event.shiftKey === false) {
            event.preventDefault();
            setActiveLoadMoreButton(false);
            setSearchQuery(keywordsRef.current);
          }
        }}
        onClick={(e) => {
          if (isValidQuery) {
            e.preventDefault();
            setActiveLoadMoreButton(false);
            setSearchQuery(keywordsRef.current);
          }
        }}
      />

      <div className={styles.calendarWrapper}>
        <Calendar
          onChange={(start, end) => {
            setStartDate(start)
            setEndDate(end)
            setCursor(null)
            setPage(1)
            if(keywordsRef?.current) {
              setSearchQuery(keywordsRef?.current)
            }
          }}
          initStartDate={initStartDate}
          initEndDate={initEndDate}
        />
      </div>

    <DropdownMenu  
      isOpen={dropdownMenu}
      header={t('Sort by')}
      onClick={() => setDropdownMenu(!dropdownMenu)}
      buttonName={t('Sort')}
      icon={<SortIcon/>}
    >
      <Radiobutton 
        itemsList={deduplication ? sortingMenuStories : sortingMenu}
        current={sorting.fieldName}
        onChange={(value) => setSorting({...sorting, fieldName: value})}
      />

      <SortingOrder 
        itemsList={sortingOrder}
        onClick={(value) => setSorting({...sorting, isAscending: value})}
        current={sorting.isAscending}
      />
    </DropdownMenu>
    <Filter
      isThreat={narrative.is_threat}
      isShare={isShare}
      platformsFilter={platforms}
      sourceGroupsFilter={sourceGroups}
      manipulationIndexFilter={manipulationIndex}
      languagesFilter={languages}
      sourcesFilter={sources}
      countriesFilter={countries}
      sentimentFilter={sentiment}
      contentTypesFilter={contentTypes}
      messagesStatusFilter={messagesStatus}
      showOnlyFavoritesFilter={showOnlyFavorites}
      onChange={(platformsList, sourceGroupsList, manipulationIndex, languages, sources, countries, sentiment, contentTypes, messagesStatus, showFavorites) => {
        setPlatforms(platformsList)
        setSourceGroups(sourceGroupsList)
        setManipulationIndex(manipulationIndex)
        setLanguages(languages)
        setSources(sources)
        setCountries(countries)
        setSentiment(sentiment)
        setContentTypes(contentTypes)
        setMessagesStatus(messagesStatus);
        setShowOnlyFavorites(showFavorites);
        if(keywordsRef?.current) {
          setSearchQuery(keywordsRef?.current)
        }
      }}
    />
  </div>

  const isOpinions = (
    (narrative?.type === "TOPIC_MODELING" && narrative?.parameters?.topic_modeling_entity === 'OPINIONS_ONLY') ||
    narrative?.type === 'IMPACT_ASSESSMENT'
  )

  const tabslist = [
    {
      title: t('Overview'),
      icon: <DistributionIcon />,
      content: (
        <Distribution
          narrative={narrative}
          isShare={isShare}
        />
      )
    },
    {
      title: t('Messages'),
      icon: <MessagesIcon />,
      content: (
        <ExtendedMessageView 
          narrative={narrative} 
          searchQuery={searchQuery}
          sorting={sortingQuery}
          page={page}
          platforms={platforms}
          sourceGroups={sourceGroups}
          manipulationIndex={manipulationIndex}
          languages={languages}
          sources={sources}
          countries={countries}
          statuses={messagesStatus}
          start={startDate}
          end={endDate}
          manipulation={manipulationIndex}
          sentiment={sentiment}
          contentTypes={contentTypes}
          cursor={cursor}
          setCursor={setCursor}
          setPage={setPage}
          isShare={isShare}
          showOnlyFavorites={showOnlyFavorites}
          approveModal={(data) =>
              setApproveConfirmModal(data)
            }

          deleteModal={
            (data) =>
            setDeleteConfirmModal(data)
          }
          searchPanel={searchPanel}
        />
      ),
    }
  ];

 if (
     (
         !["INCIDENT", "TOPIC_MODELING"].includes(narrative?.type) && 
         narrative?.is_manual === false && 
         narrative.is_threat === false
     ) || isOpinions
 ) {
    tabslist.push(
      {
        title: isOpinions ? t('Opinions') : t('Stories'),
        icon: <StoriesIcon />,
        content: (
          <NarrativeStories
            narrative={narrative}
            searchQuery={searchQuery}
            sorting={sortingQuery}
            statuses={messagesStatus}
            showOnlyFavorites={showOnlyFavorites}
            page={page}
            setPage={setPage}
            start_date={startDate}
            end_date={endDate}
            platforms={platforms}
            sourceGroups={sourceGroups}
            manipulationIndex={manipulationIndex}
            languages={languages}
            sources={sources}
            countries={countries}
            searchPanel={searchPanel}
            cursor={cursor}
            setCursor={setCursor}
            approveModal={(data) =>
              setApproveConfirmModal(data)
            }
            deleteModal={(data) =>
              setDeleteConfirmModal(data)
            }
            isOpinions={isOpinions}
          />
        )
      }
    )
  }

  if(((narrative?.type === "TOPIC_MODELING" && narrative?.parameters?.topic_modeling_entity !== 'OPINIONS_ONLY') && narrative?.is_manual === false) && narrative.is_threat === false) {
    tabslist.push(
      {
        title: t('Stories'),
        icon: <StoriesIcon />,
        content: (
          <TopicReportPageNew isShare={isShare}/>
        )
      }
    )
  }

  if(narrative?.type !== 'IMPACT_ASSESSMENT') {
    tabslist.push(
      {
        title:  t('Actors'),
        icon: <ReportIcon />,
        content: <BreakdownsWrapper isShare={isShare} narrative={narrative}/>
      }
    )
  }

  if(narrative?.is_threat === false && !isShare) {
    tabslist.push(
      {
        'title': t('Edit history'),
        icon: <HistoryIcon />,
        content: <NarrativeEditHistory narrativeId={narrativeId || threatId} />,
      }
    );
  }

  const handleSaveAsThreat = ({narrative, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic}) => {
    let parameters = null;
    if (narrative.parameters !== null) {
      parameters = {
        keywords: narrative.parameters.keywords || null,
        examples: narrative.parameters.examples || null,
        start_date: narrative.parameters.start_date || null,
        end_date: narrative.parameters.end_date || null,
        apply_supervised_classifier: narrative.parameters.apply_supervised_classifier,
        source_types: narrative.parameters.source_types || [],
        source_group_ids: narrative.parameters.source_group_ids || [],
        disable_filters: narrative.parameters.disable_filters,
        languages: narrative.parameters.languages || [],
        source_ids: narrative.parameters.source_ids || [],
        source_origin_country_ids: narrative.parameters.source_origin_country_ids || [],
        sentiment: narrative.parameters.sentiment || [],
        translate_keywords_query: narrative.parameters.translate_keywords_query,
        keywords_query_origin_language: narrative.parameters.keywords_query_origin_language || '',
        content_types: narrative.parameters.content_types || [],
        similarity_threshold: narrative.parameters.similarity_threshold || null,
      }
    }

    api.fetch('POST', `/API/v1/narratives/${narrative.id}/copies`, null, {
      name: threatName,
      is_public: threatIsPublic,
      public_name_en: threatPublicNameEn,
      public_name_uk: threatPublicNameUk,
      public_description_en: threatPublicDescriptionEn,
      public_description_uk: threatPublicDescriptionUk,
      description: threatDescription,
      is_active: narrative.is_active,
      type: caseType?.value,
      is_threat: true,
      workspace_ids: [1],
      incident_type: incidentType?.map(v => v.value),
      topic: topics?.map(v => v.value),
      geo_scope: geoScope?.map(v => v.value),
      narrative_ids: narratives?.map(v => v.value),
      campaign_ids: campaigns?.map(v => v.value),
      counter_entity_ids: counterEntities?.map(v => v.value),
      parameters: parameters,
      adjustment_suggestions: null,
      is_created_by_ucbi_reporter: isCreatedByUcbiReporter,
    }).then((data) => {
      ampli.track({
        event_type: 'Share as a threat',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        }
      });

      navigate(`/threats/${data.id}`);
    });
  }

  const saveAsNarrative = (threat) => {
    let parameters = null;

    if (threat.parameters !== null) {
      parameters = {
        keywords: threat.parameters.keywords || null,
        examples: threat.parameters.examples || null,
        start_date: threat.parameters.start_date || null,
        end_date: threat.parameters.end_date || null,
        apply_supervised_classifier: threat.parameters.apply_supervised_classifier,
        source_types: threat.parameters.source_types || [],
        source_group_ids: threat.parameters.source_group_ids || [],
        disable_filters: threat.parameters.disable_filters,
        languages: threat.parameters.languages || [],
        source_ids: threat.parameters.source_ids || [],
        source_origin_country_ids: threat.parameters.source_origin_country_ids || [],
        sentiment: threat.parameters.sentiment || [],
        translate_keywords_query: threat.parameters.translate_keywords_query,
        keywords_query_origin_language: threat.parameters.keywords_query_origin_language || '',
        content_types: threat.parameters.content_types || [],
        similarity_threshold: threat.parameters.similarity_threshold || null,
      }
    }

    api.fetch('POST', `/API/v1/narratives/${threat.id}/copies`, null, {
      name: threat.name,
      description: threat.description,
      is_active: threat.is_active,
      type: 'NARRATIVE',
      is_threat: false,
      is_manual: threat.parameters === null,
      incident_type: threat.incident_type,
      topic: threat.topic,
      parameters: parameters,
      adjustment_suggestions: null,
    }).then((data) => {
      ampli.track({
        event_type: 'Save threat',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          threat_id: threat.id,
        }
      });

      navigate('/narratives');
    });
  }

  const patchThreat = ({threat, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic, workspaceId}) => {
    let data = {
      name: threatName,
      description: threatDescription,
      is_public: threatIsPublic,
      public_name_en: threatPublicNameEn,
      public_name_uk: threatPublicNameUk,
      public_description_en: threatPublicDescriptionEn,
      public_description_uk: threatPublicDescriptionUk,
    };

    const headers = { 'Content-Type': 'application/json' };

    if(workspaceId) {
      headers['workspace-id'] = workspaceId;
    }
    
    if(caseType) {
      data.type = caseType.value;
    }

    if(incidentType) {
      data.incident_type = incidentType.map(v => v.value);
    }

    if(topics) {
      data.topic = topics.map(v => v.value);
    }

    if(geoScope) {
      data.geo_scope = geoScope.map(v => v.value);
    }

    if(narratives) {
      data.narrative_ids = narratives.map(v => v.value);
    }

    if(campaigns) {
      data.campaign_ids = campaigns.map(v => v.value);
    }

    if(counterEntities) {
      data.counter_entity_ids = counterEntities.map(v => v.value);
    }

    if(reviewStatus) {
      data.review_status = reviewStatus;
    }

    if(declineReason) {
      data.decline_reason = declineReason;
    }

    if(organizations) {
      data.workspace_ids = organizations.map( v => v.value);
    }

    data.is_created_by_ucbi_reporter = isCreatedByUcbiReporter

    api.fetch('PATCH', `/API/v1/narratives/${threat.id}`, headers, data).then((data) => {
      fetchNarrative(data.id)
    });
  };

  const saveToThreat = (narrativeId, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic) => {
    
    const messagesList = Object.keys(selectedMessages);
    
    let data = {
      name: threatName,
      description: threatDescription,
      is_threat: true,
      is_public: threatIsPublic,
      public_name_en: threatPublicNameEn,
      public_name_uk: threatPublicNameUk,
      public_description_en: threatPublicDescriptionEn,
      public_description_uk: threatPublicDescriptionUk,
    };

    const headers = { 'Content-Type': 'application/json' };
    
    if(caseType) {
      data.type = caseType.value;
    }

    if(incidentType) {
      data.incident_type = incidentType.value;
    }

    if(topics) {
      data.topic = topics.map(v => v.value);
    }

    if(geoScope) {
      data.geo_scope = geoScope.map(v => v.value);
    }

    if(narratives) {
      data.narrative_ids = narratives.map(v => v.value);
    }

    if(campaigns) {
      data.campaign_ids = campaigns.map(v => v.value);
    }

    if(counterEntities) {
      data.counter_entity_ids = counterEntities.map(v => v.value);
    }

    if(reviewStatus) {
      data.review_status = reviewStatus;
    }

    if(declineReason) {
      data.decline_reason = declineReason;
    }

    if(organizations) {
      data.workspace_ids = organizations.map( v => v.value);
    }

    data.is_created_by_ucbi_reporter = isCreatedByUcbiReporter

    let body = {
      new_narrative: data,
      existing_narrative_id: null,
      story_ids: messagesList,
    }

    API.fetch('POST', `/API/v1/narratives/${narrativeId}/copy_from_stories`, null, body).then()
  }


  const isUcbiUser = (
    currentUser?.workspace?.config?.is_ucbi_admin
    || currentUser?.workspace?.config?.is_reporter_only_user
  );

  let isNarrativeActive = narrative.is_active === true ? 'active' : 'inactive'
                
  let caseType = null;

  if(narrative?.type === "NARRATIVE" && narrative.is_manual === true) {
    caseType = t('Manual')
  } else if (narrative?.type === "NARRATIVE") {
    caseType = t('Narrative')
  } else if (narrative?.type === "INCIDENT") {
    caseType = t('Incident')
  } else if (narrative?.type === "IMPACT_ASSESSMENT") {
    caseType = t('Impact assessment')
  } else if (narrative.type === "TOPIC_MODELING") {
    caseType = t('Topic modeling')
  }

  const mapStatusClasses = {
    'active': styles.active,
    'inactive': styles.inactive
  }

  const getThreatName = (threat) => {
    if (!threat?.is_public) {
      return threat?.name
    }

    let public_name;
    if(
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      public_name = threat?.public_name_uk;
    } else {
      public_name = threat?.public_name_en;
    }

    return public_name ?? threat?.name
  }

  const caseName = narrative.is_threat ? getThreatName(narrative) : narrative.name;

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            {(currentUser?.workspace?.config?.is_reporter_only_user && narrative.is_threat) ? <Link to="/threats">{t('Instances')}</Link> : (
              isShare ? t('Threats') : narrative.is_threat ? <Link to="/threats">{t('Threats')}</Link> : <Link to="/narratives">{t('Cases')}</Link>
            )}
          </span>
          <span>{cutString(caseName, 60)}</span>
        </div>
        <div className="controls">
          {isShare ? (
            ''
          ) : (
            <>
              {!narrative.is_threat ? 
              <Tooltip content={isUcbiUser ? t('Share as an instance') : t('Share as a threat')} position='bottom'>
                <button
                  type='button'
                  className={cn('new-button')}
                  disabled={narrative?.type === 'IMPACT_ASSESSMENT' && narrativeStatus !== 'DONE'}
                  onClick={() => {
                    checkNarrative()
                    const falseKeys = Object.keys(isNarrativeValid).filter(key => isNarrativeValid[key] === false)
                  if(falseKeys.length > 0) {
                    setErrorModal(true)
                  } else {
                    setThreatName(narrative.name)
                    setThreatDescription(narrative.description)
                    setSaveAsThreatModal({isOpen: true, narrative: narrative})
                  }
                }}
              >
                <ShareThreatIcon />
                  {/* {t('Share as a threat')} */}
                </button>
              </Tooltip> : ''}
              {currentUser?.is_super_admin === true && (!narrative.is_threat)? (
                <Tooltip content={t('Copy')} position='bottom'>
                  <button
                    type="button"
                    className={cn('new-button')}
                    disabled={narrative?.type === 'IMPACT_ASSESSMENT' && narrativeStatus !== 'DONE'}
                    onClick={() =>
                      setCopyNarrativeModal({
                        isActive: true,
                      })
                    }
                  >
                    <CopyIcon/>
                    {/* {t('Copy')} */}
                  </button>
                </Tooltip>
              ) : (
                ''
              )}
              {narrative.is_threat && !currentUser?.workspace?.config?.is_reporter_only_user ? (
                <Tooltip content={t('Save as narrative')} position='bottom'>
                  <button
                  type="button"
                  className={cn('new-button')}
                  onClick={() => setSaveAsNarrativeModal({isOpen: true, threat: narrative})}
                >
                  <SaveIcon />
                  {/* {t('Save')} */}
                </button>
                </Tooltip>
              ) : ''}
              {!narrative.is_threat ? <Tooltip content={t('Edit')} position='bottom'>
                <Link
                  to={`/narratives/${narrative.id}/edit`}
                  className={cn(styles.buttonText, 'button', 'new-button')}
                >
                  <EditIcon />
                  {/* {t('Edit')} */}
                </Link>
              </Tooltip> : ''}
              {/* <Tooltip content={t('Edit history')} position='bottom'>
                <Link
                  to={`/narratives/${narrative.id}/edit/history`}
                  className={cn(styles.buttonText, 'button', 'new-button')}
                >
                  <HistoryIcon />
                </Link>
              </Tooltip> */}
              {(narrative.is_threat && narrative.review_status === 'PENDING') || (narrative.is_threat && (currentUser?.is_super_admin === true || currentUser?.workspace?.config?.is_ucbi_admin)) 
                ? <Tooltip content={t('Edit')} position='bottom'>
                  <button 
                    className={cn(styles.buttonText, 'button', 'new-button')}
                    onClick={() => setEditThreatModal({isOpen: true, threat: narrative})}
                    disabled={narrative?.type === 'IMPACT_ASSESSMENT' && narrativeStatus !== 'DONE'}
                  >
                    <EditIcon />
                    {/* {t('Edit')} */}
                  </button> 
                </Tooltip>
                : ''}
              <Tooltip content={t('Share')} position='bottom'>
                <button
                  type="button"
                  className="button new-button"
                  disabled={narrative?.type === 'IMPACT_ASSESSMENT' && narrativeStatus !== 'DONE'}
                  onClick={() =>
                    setExportModal({ isActive: true, id: narrative.id })
                  }
                >
                  <ShareIcon />
                  {/* {t('Share')} */}
                </button>
              </Tooltip>
              <DropdownMenu
                isOpen={isExportMenuOpen}
                header={t('Format')}
                onClick={() => setIsExportMenuOpen(!isExportMenuOpen)}
                buttonName={t('Export')}
                icon={<ChevronDown />}
                menuClassName='export'
                disabled={narrative?.type === 'IMPACT_ASSESSMENT' && narrativeStatus !== 'DONE'}
              >
                <ul>
                  <li
                    type="li"
                    className="new-li"
                    onClick={() =>{
                      setIsExportMenuOpen(false);
                      // handleExport('csv', narrative.id, sortingQuery, searchQuery);
                      handleAsyncExport('csv', narrative.id);
                    }}
                  >
                    {t('CSV')}
                  </li>
                  <li
                    type="li"
                    className="new-li"
                    onClick={() => {
                      setIsExportMenuOpen(false);
                      // handleExport('json', narrative.id, sortingQuery, searchQuery);
                      handleAsyncExport('jsonl', narrative.id);
                    }}
                  >
                    {t('JSON')}
                  </li>
                  <li
                    type="li"
                    className="new-li"
                    onClick={() => {
                      setIsExportMenuOpen(false);
                      // handleExport('xml', narrative.id, sortingQuery, searchQuery);
                      handleAsyncExport('xml', narrative.id);
                    }}
                  >
                    {t('XML')}
                  </li>
                  <li
                    type="li"
                    className="new-li"
                    onClick={() => {
                      setIsExportMenuOpen(false);
                      handleExport('docx', narrative.id, sortingQuery, searchQuery);
                    }}
                  >
                    {t('DOCX')}
                  </li>
                </ul>
              </DropdownMenu>
            </>
          )}
        </div>
      </div>
      <div className="next-card-header">
        <div className="row">
          <h2>{''}</h2>
          {Object.keys(selectedMessages).length > 0 ? <div className={styles.bulkButtonsWrapper} id='bulk'>
            <button>{Object.keys(selectedMessages).length} {t('selected')} <span className={styles.clear} onClick={() => clearAllSelectedMessages()}><CloseIcon/></span> </button>
            {!addToActorGroup || deduplication ? '' : <button
              onClick={() => setAddToSourceGroupModal(true)}
            >
              <AddToIcon/>
              {t('Add to actor group')}
            </button>}
            <button
              onClick={() => setAddToNarrativeModal(true)}
            >
              <AddToIcon/>
              {t('Add to case')}
            </button>
            {deduplication ? <button
              onClick={() => setAddToThreatModal(true)}
            >
              <ShareThreatIcon/>
              {t('Add to threat')}
            </button> : ''}
            {deduplication ? <button
              onClick={() => setMergeStoriesModal({
                isActive: true,
                story_ids: selectedMessagesKeys,
                new_summary: messages?.objects?.filter(message => selectedMessagesKeys.includes(message.id.toString())).sort((a, b) => b.messages_count - a.messages_count)?.[0]?.summary || '',
                isLoading: false,
                error: null,
              })}
            >
              <MergeIcon/>
              {t('Merge')}
            </button> : ''}
            <button
              onClick={() => setApproveConfirmModal({isActive: true, narrative: narrative, status: 'READ', ids: selectedMessagesKeys})}
            >
              <ApproveIcon/>
              {t('Approve')}
            </button>
            <button
              onClick={() => setApproveConfirmModal({isActive: true, narrative: narrative, status: 'NEW', ids: selectedMessagesKeys})}
            >
              <MailIcon/>
              {t('Mark as unread')}
            </button>
            <button
              onClick={() => setDeleteConfirmModal({isActive: true, narrative: narrative, status: 'NEW', ids: selectedMessagesKeys})}
            >
              <DeleteIcon/>
              {t('Delete')}
            </button>
          </div> : ''}
        </div>
      </div>

      <AdjustmentSuggestions
        narrative={narrative}
        messages={messages}
        onSuggestionApply={() => fetchNarrative(narrative.id)}
      />
      
     

      {narrative && !narrative?.is_threat ? <div className='report-section'>
        <div className={styles.parametersCard}>
          <h3 className={styles.headerInfoTitle}>{narrative?.name}</h3>
          <div className={styles.headerInfo}>
            <ul className={styles.headerList}>
              {narrative?.type === 'IMPACT_ASSESSMENT' ? '' : <li>
                <span>{t('Start date')}:</span> {narrative?.parameters?.start_date ? narrative?.parameters?.start_date : '-'}
              </li>}
              {narrative?.type === 'IMPACT_ASSESSMENT' ? '' : <li>
                <span> {t('End date')}:</span>
                {narrative?.parameters?.end_date ? narrative?.parameters?.end_date : '-'}
              </li>}
              <li>
                <span>{narrative?.is_threat === true ? t('Threat type') : t('Case type')}:</span>{caseType}
              </li>
              <li>
                {narrative?.is_threat === true
                  ? ''
                  : <>
                      <span>{t('Status')}:</span> <span className={`${mapStatusClasses[isNarrativeActive]}`}>{narrative?.is_active ? t('Active') : t('Inactive')}</span>
                   </>}
              </li>
              {narrative?.type === 'IMPACT_ASSESSMENT' ? <li>
                <span> {t('Link to content1')}:</span>
                {extractPlatformName(narrative.parameters.content_link)}
                <span>
                  <a
                    href={narrative.parameters.content_link}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.externalLink}
                  >
                    <LinkIcon/>
                    </a>
                </span>
              </li> : ''}
            </ul>
          </div>
        </div>
        
        </div> : ''}

      {narrative?.is_threat ? <div className='report-section'>
          <div className={styles.parametersCard}>
            <h3 className={styles.headerInfoTitle}>{narrative?.name}</h3>
            <div className={styles.headerInfo}>
              <ul className={styles.headerList}>
                <li>
                  <span>{t('Start date')}:</span> {narrative?.parameters?.start_date ? narrative?.parameters?.start_date : '-'}
                </li>
                <li>
                  <span>{t('Status')}:</span> {narrative?.review_status ? <div className={ `${styles.statusWrapper} ${mapThreatStatusClasses[narrative?.review_status]}`}>{t(mapThreatsStatus[narrative?.review_status])}</div> : '-'}
                </li>
              </ul>
              <div className={styles.headerTags}>
                <div className={styles.tagsContainer}>
                  {narrative?.incident_type?.map((tag, index) => {
                    return (
                      <span key={`${tag}${index}`} className={styles.badge}>
                        {t(mapThreatsTypesConst[tag])}
                      </span>
                    );
                  })}
                </div>
                <div className={styles.tagsContainer}>
                    {narrative?.topic?.map((subject, index) => {
                      return (
                        <span
                          key={`${subject}${index}`}
                          className={`${styles.mark}`}
                        >
                          {t(mapThreatsSubjects[subject])}
                        </span>
                      );
                    })}
                  </div>
              </div>
            </div>
          </div>
        </div> : ''}
            



      {narrative?.type === "IMPACT_ASSESSMENT" && narrativeStatus !== 'DONE' ? <CaseStatusbar status={narrativeStatus}/> : <Tabs
        tabslist={tabslist}
        // switchMode={messageModeList}
        onModeIndexChange={(index) => setMessageModeIndex(index)}
        onIndexChange={(index) => {
          setTabIndex(index)
          clearAllSelectedMessages()

          setPlatforms([])
          setSourceGroups([])
          setManipulationIndex([])
          setLanguages([])
          setSources([])
          setCountries([])
          setSentiment([])
          setContentTypes(defaultContentTypes)
          setMessagesStatus([])
          setShowOnlyFavorites(false);
          setSearchQuery(null)
          setStartDate(null)
          setEndDate(null)
          setSearchQuery(null)
          
          toggleTab()
          if((tabslist[index].title === 'Stories' || tabslist[index].title === 'Сюжети' || tabslist[index].title === 'Opinions' || tabslist[index].title === 'Точки зору') && !deduplication) {
            toggleDeduplication(true)
            setSorting({...sorting, fieldName: 'DEDUPLICATION'})
            applySearch(false)
            setCursor(null)
            setPage(1)
          } else if ((tabslist[index].title !== 'Stories' || tabslist[index].title === 'Сюжети') && deduplication) {
            toggleDeduplication(false)
            setSorting({...sorting, fieldName: 'DATE'})
            applySearch(false)
            setCursor(null)
            setPage(1)
          }
        }}
        // activeMode={messageModeIndex}
      />}

      <Modal
        isVisible={approveConfirmModal.isActive}
        title={approveConfirmModal.status === 'READ' ? t('Approve') : t('Mark as unread')}
        content={
          <p>
            {approveConfirmModal.status === 'READ' ? (approveConfirmModal.ids && approveConfirmModal.ids.length > 1
              ? t('Are you sure you want to approve these messages?')
              : t('Are you sure you want to approve this message?'))
              : (approveConfirmModal.ids && approveConfirmModal.ids.length > 1
                ? t('Are you sure you want to mark these messages as unread?')
                : t('Are you sure you want to mark this message as unread?'))
            }
          </p>
        }
        footer={
          <>
            <button
              className="btn-primary"
              onClick={() => {
                patchMessages(
                  approveConfirmModal.narrative,
                  approveConfirmModal.status,
                  approveConfirmModal.ids,
                  deduplication,
                ).then(() => {
                  dispatch({type: 'PATCH_MESSAGES'})
                  getMessages(
                    narrative,
                    searchQuery,
                    sortingQuery,
                    messagesStatus,
                    deduplication,
                    page,
                    showOnlyFavorites,
                    startDate,
                    endDate,
                    platforms,
                    sourceGroups,
                    manipulationIndex,
                    isValidQuery,
                    languages,
                    sources,
                    countries,
                    sentiment,
                    contentTypes
                  );
                })
                setApproveConfirmModal({
                  isActive: false,
                  narrative: null,
                  status: null,
                  ids: null,
                });
              }}
            >
              {approveConfirmModal.status === 'READ' ? t('Approve') : t('Confirm')}
            </button>
            <button
              className="btn-reset"
              onClick={() =>
                setApproveConfirmModal({
                  isActive: false,
                  narrative: null,
                  status: null,
                  ids: null,
                })
              }
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() =>
          setApproveConfirmModal({
            isActive: false,
            narrative: null,
            status: null,
            id: null,
          })
        }
      />
      <Modal
        isVisible={deleteConfirmModal.isActive}
        title={deleteConfirmModal.status === 'DELETED' ? t('Restore') : t('Delete')}
        content={
          <p>
            {deleteConfirmModal.status !== 'DELETED' ? (deleteConfirmModal.ids && deleteConfirmModal.ids.length > 1
              ? t('Are you sure you want to delete these messages?')
              : t('Are you sure you want to delete this message from a narrative?'))
              : (deleteConfirmModal.ids && deleteConfirmModal.ids.length > 1
                ? t('Are you sure you want to restore these messages?')
                : t('Are you sure you want to restore this message?'))
            }
          </p>
        }
        footer={
          <>
            <button
              className="btn-danger"
              onClick={() => {
                if(deleteConfirmModal.status === "DELETED") {
                  patchMessages(
                    deleteConfirmModal.narrative,
                    'NEW',
                    deleteConfirmModal.ids,
                    deduplication,
                  ).then(() => {
                    dispatch({type: 'PATCH_MESSAGES'})
                    getMessages(
                      narrative,
                      searchQuery,
                      sortingQuery,
                      messagesStatus,
                      deduplication,
                      page,
                      showOnlyFavorites,
                      startDate,
                      endDate,
                      platforms,
                      sourceGroups,
                      manipulationIndex,
                      isValidQuery,
                      languages,
                      sources,
                      countries,
                      sentiment,
                      contentTypes
                    );
                  })
                  setDeleteConfirmModal({
                    isActive: false,
                    narrative: null,
                    status: null,
                    ids: null,
                  })
                } else {
                  deleteMessages(
                    deleteConfirmModal.narrative,
                    deleteConfirmModal.ids,
                    deduplication,
                  ).then(() => {
                    dispatch({type: 'DELETE_MESSAGES'});
                    getMessages(
                      narrative,
                      searchQuery,
                      sortingQuery,
                      messagesStatus,
                      deduplication,
                      page,
                      showOnlyFavorites,
                      startDate,
                      endDate,
                      platforms,
                      sourceGroups,
                      manipulationIndex,
                      isValidQuery,
                      languages,
                      sources,
                      countries,
                      sentiment,
                      contentTypes
                    );
                  });
                  setDeleteConfirmModal({
                    isActive: false,
                    narrative: null,
                    status: null,
                    ids: null,
                  })
                }
              }}
            >
              {deleteConfirmModal.status === 'DELETED' ? t('Restore') : t('Delete')}
            </button>
            <button
              className="btn-reset"
              onClick={() =>
                setDeleteConfirmModal({
                  isActive: false,
                  narrative: null,
                  status: null,
                  ids: null,
                })
              }
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() =>
          setDeleteConfirmModal({
            isActive: false,
            narrative_id: null,
            status: null,
            id: null,
          })
        }
      />

      <Modal
        isVisible={mergeStoriesModal.isActive}
        title={t('Merge stories')}
        doNotCloseOnOutsideClick={true}
        content={
          mergeStoriesModal.story_ids.length < 2 ? <p className={styles.errorStoriesMerge}>
            {t('Please select at least 2 stories to merge')}
          </p> : <div className={styles.mergeStoriesModal}>
            {mergeStoriesModal.error ? <p className={styles.errorStoriesMerge}>{mergeStoriesModal.error}</p> : '' }
            <div className={styles.formElement}>
              <label htmlFor="new-story-summary">{t('Description')}&#42;</label>
              <input
                type="text"
                id="new-story-summary"
                value={mergeStoriesModal.new_summary}
                required
                onChange={(e) => setMergeStoriesModal({...mergeStoriesModal, new_summary: e.target.value})}
              />
            </div>
          </div>
        }
        footer={
          <>
            {mergeStoriesModal.story_ids.length < 2 ? '' : <button
              className="btn-primary"
              disabled={mergeStoriesModal.isLoading}
              onClick={() => {
                setMergeStoriesModal({
                  ...mergeStoriesModal,
                  isLoading: true,
                })

                API.fetch('POST', `/API/v1/narrative_stories/${narrative.id}/merge`, null, {
                  'story_ids': mergeStoriesModal.story_ids,
                  'new_summary': mergeStoriesModal.new_summary,
                }).then(() => {
                  setMergeStoriesModal({
                    isActive: false,
                    story_ids: [],
                    new_summary: '',
                    isLoading: false,
                    error: null,
                  })

                  clearSelectedMessages()

                  getStories(
                    narrative,
                    searchQuery,
                    startDate,
                    endDate,
                    messagesStatus,
                    sortingQuery,
                    page
                  );
                }).catch(() => {
                  setMergeStoriesModal({
                    ...mergeStoriesModal,
                    isLoading: false,
                    error: t('Something went wrong. Please, try again...'),
                  })
                });
              }}
            >
              { mergeStoriesModal.isLoading ? <LoaderSmall/> : t('Merge') }
            </button> }
            <button
              className="btn-reset"
              disabled={mergeStoriesModal.isLoading}
              onClick={() =>
                setMergeStoriesModal({
                  isActive: false,
                  story_ids: [],
                  new_summary: '',
                  isLoading: false,
                  error: null,
                })
              }
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() =>
          setMergeStoriesModal({
            isActive: false,
            story_ids: [],
            new_summary: '',
            isLoading: false,
            error: null,
          })
        }
      />
      
      {saveAsThreatModal.isOpen ? <ThreatEditModal
        isOpen={saveAsThreatModal.isOpen}
        title={isUcbiUser ? t('Share as an instance') : t('Share as a threat')}
        threat={saveAsThreatModal.narrative}
        onChange={(bool) => setSaveAsThreatModal({isOpen: bool, threat: null})}
        create={true}
        handleThreat={({threat, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic}) => handleSaveAsThreat({narrative: threat, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic})}
        message={t("You are about to share your case as a threat. Once submitted, it will become visible to other users of our system and you won’t be able to edit it anymore. Please, double-check that the content of the case and its settings are accurate.")}
      /> : ''}

      {editThreatModal.isOpen ? <ThreatEditModal
        isOpen={editThreatModal.isOpen}
        title={isUcbiUser ? t('Edit the instance') : t('Edit the threat')}
        threat={editThreatModal.threat}
        isAdmin={currentUser?.is_super_admin}
        onChange={(bool) => setEditThreatModal({isOpen: bool, threat: null})}
        handleThreat={({threat, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic}) => patchThreat({threat, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic, workspaceId: (currentUser.is_super_admin) ? threat.workspace_id : null})}
      /> : ''}

      {addToThreatModal ? <ThreatEditModal
        isOpen={addToThreatModal}
        title={t('Add to a threat')}
        threat={narrative}
        isAdmin={currentUser?.is_super_admin}
        onChange={(bool) => setAddToThreatModal(bool)}
        handleThreat={({threat, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic}) => saveToThreat(narrativeId, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic)}
      /> : ''}

      <Modal
        isVisible={saveAsNarrativeModal.isOpen}
        title={t('Save as a case')}
        content={<p>{t('Are you sure you want to save the threat as a case?')}</p>}
        footer={
          <>
            <button
              className="btn-primary"
              onClick={() => {
                saveAsNarrative(saveAsNarrativeModal.threat)
                setSaveAsNarrativeModal({ isOpen: false, threat: null })
              }}
              
            >
              {t('Save')}
            </button>
            <button
              className="btn-reset"
              onClick={() =>
                setSaveAsNarrativeModal({ isOpen: false, threat: null })
              }
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setSaveAsNarrativeModal({ isOpen: false, threat: null })}
      />
   

      {errorModal ? <Modal
        isVisible={errorModal}
        title={t('The case does not meet requirements for sharing')}
        className={styles.errorModal}
        content = {
        <div className={styles.errorModalText}>
          <div>
            {t(`This case can’t be shared as a threat because it doesn't meet the necessary criteria. Please update the case according to the following requirements:`)}
          </div>
          <ul>
            {/* {!isNarrativeValid.description 
              ? <li>
                <span>{t('Description Required: ')}</span> 
                {t(`Please provide a description for this case.`)}</li>
              : ''} */}
            {!isNarrativeValid.fiveMessages 
              ? <li>
                <span>{t('Minimum 3 Confirmed Messages: ')}</span> 
                {t(`To share a case as a threat, it should contain at least 3 confirmed messages.`)}</li> 
              : ''}
            {/* {!isNarrativeValid.allMessages 
              ? <li>
                  <span>{t('All Messages Confirmation: ')}</span> 
                  {t(`Ensure that all messages in the case are either confirmed or deleted.`)}</li> 
              : ''} */}
          </ul>
          
          <div>
            {isUcbiUser ? (
              t(`Once these requirements are met, you can click the 'Share as an instance' button again, and your case will be shared successfully.`)
            ) : (
              t(`Once these requirements are met, you can click the 'Share as a threat' button again, and your case will be shared successfully.`)
            ) }
            </div>
        </div>
        }
        footer={
          <>
            <button className="btn-reset" onClick={() => setErrorModal(false)}>{t('Close')}</button>
          </>
        }
        onClose={() =>
          setErrorModal(false)
        }
      /> : ''}
      {currentUser?.is_super_admin === true ? (
        <CopyNarrativeToWorkspacePopup
          isVisible={copyNarrativeModal.isActive}
          narrativeId={narrative.id}
          onClose={() => setCopyNarrativeModal({ isActive: false })}
        />
      ) : (
        ''
      )}


      {addToNarrativeModal ? <AddToNarrativeModal
        isOpen={addToNarrativeModal}
        messages={selectedMessages}
        deduplication={deduplication}
        caseId={narrative.id}
        onChange={(bool) => {
          setAddToNarrativeModal(bool)
        }}/> : ''}

      {addToSourceGroupMadal ? <AddToSourceGroupModal
        isOpen={addToSourceGroupMadal}
        messages={selectedMessages}
        deduplication={deduplication}
        onChange={(bool) => {
          setAddToSourceGroupModal(bool)
        }}/> : ''}

      <ExportReportPopup
        isVisible={exportModal.isActive}
        onClose={() => setExportModal({ isActive: false, id: null })}
        narrativeId={narrative.id}
      />

    </div>
  );
}
