import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { API } from '../../API';
import { Loader } from '../../components/Loader/Loader';
import { SourcesTable } from '../../components/SourcesTable/SourcesTable';
import { Search } from '../../components/Search/Search';
import { CheckboxField } from '../../components/Checkbox/CheckboxField';
import {ReactComponent as EditIcon} from '../../assets/edit.svg';
import { GroupEditModal } from '../GroupsPage/GroupEditModal';
import Tooltip from '../../components/Tooltip/Tooltip';
import styles from './GroupPage.module.scss';
import tableStyles from '../../components/SourcesTable/SourcesTable.module.scss'
import { useCurrentUser } from '../../contexts/CurrentUser';
import CopyGroupToWorkspacePopup from "../../components/CopyGroupToWorkspacePopup";
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';

export function GroupPage() {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [sources, setSources] = useState(null);
  const [shouldShowSelected, setShouldShowSelected] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editModal, setEditModal] = useState({isOpen: false, group: null});
  const [error, setError] = useState(null);
  const [copyGroupModal, setCopyGroupModal] = useState({
    isActive: false,
    workspaceId: null,
  });

  const page = searchParams.get('page') ?? 1;
  const searchQuery = searchParams.get('q');

  const fetchSources = useMemo(
    () =>
      debounce(
        () => {
          setSources(null)
          const urlParams = new URLSearchParams();
          urlParams.set('size', '100');
          urlParams.set('page', page);

          if (searchQuery) {
            urlParams.set('q', searchQuery);
          }

          const url = shouldShowSelected
            ? `/API/v1/groups/${groupId}/sources?${urlParams.toString()}`
            : `/API/v1/sources?${urlParams.toString()}`;

          API.fetch('GET', url).then(setSources);
        },
        300,
        { maxWait: 3000 },
      ),
    [groupId, page, searchQuery, shouldShowSelected],
  );

  useEffect(() => {
    API.fetch('GET', `/API/v1/groups/${groupId}`).then(setGroup).catch(error => {
      setError(error.message)
    });
  }, [groupId]);

  useEffect(fetchSources, [fetchSources]);

  const hideSelectedWithDebounce = useMemo(
    () => debounce(() => setShouldShowSelected(false), 3500),
    [],
  );

  useEffect(() => {
    if (sources && sources.total === 0 && shouldShowSelected) {
      hideSelectedWithDebounce();
    }

    return () => {
      hideSelectedWithDebounce.cancel();
    };
  }, [sources, shouldShowSelected, hideSelectedWithDebounce]);

  const checkIsSelected = useCallback(
    (source) =>
      Boolean(
        shouldShowSelected
          ? true
          : source &&
              source.groups &&
              source.groups.some(
                (group) => String(group.id) === String(groupId),
              ),
      ),
    [shouldShowSelected, groupId],
  );

  const [loadingSet, setLoadingSet] = useState(new Set());

  const handleEdit = (name, isPublic, group) => {
    API.fetch('PATCH', `/API/v1/groups/${group.id}`, null, {name: name, is_public: isPublic}).then(() => {
      API.fetch('GET', `/API/v1/groups/${groupId}`).then(setGroup);
    })
  }

  if(!group && error) {
    return <div className='noData'>{t(error)}</div>
  }

  if (!group) {
    return <Loader />;
  }

  return (
    <div className={cn('list-content', styles.root)}>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/groups">{t('Actor groups')}</Link>
          </span>
          <span>{group.name}</span>
        </div>
        <div className="controls">
          {currentUser?.is_super_admin === true ? (
            <Tooltip content={t('Copy')} position='bottom'>
              <button
                type="button"
                className={cn('new-button')}
                onClick={() =>
                  setCopyGroupModal({
                    isActive: true,
                  })
                }
              >
                <CopyIcon/>
              </button>
            </Tooltip>
          ) : (
            ''
          )}

          {group.owned_by_workspace ? <Tooltip content={t('Edit')} position="bottom">
            <button
              className='button new-button'
              onClick={() => setEditModal({isOpen: true, group: group})}
            >
              <EditIcon />
            </button>
          </Tooltip> : ''}
        </div>
      </div>

      {group.owned_by_workspace ? <div className="next-card-header">
        <div className="row">
          <h1></h1>

          <label className={styles.showOnlySelected}>
            <CheckboxField
              checked={shouldShowSelected}
              onChange={() => {
                setSearchParams({page: 1})
                setShouldShowSelected((state) => !state)
              }}
            >
              {t('Show selected')}
            </CheckboxField>
          </label>
        </div>

        <div className='search-wrapper'>
          <Search 
            onChange={value => {
              setSearchParams({q: value, page: 1})
            }}
          />
        </div>
        
      </div> : ''}

      <SourcesTable
        rowClassName={styles.tableRow}
        sources={sources}
        withHeader={false}
        searchQuery={searchQuery}
        checkIsSelected={checkIsSelected}
        renderPrefixRow={(source) => {
          const isSelected = checkIsSelected(source);

          if (! group.owned_by_workspace) {
            return <td></td>;
          }

          return (
            <td className={tableStyles.tableCheckbox}>
              <CheckboxField
              className={styles.tableRow}
              checked={isSelected}
              isLoading={loadingSet.has(source.id)}
              onChange={async () => {
                setLoadingSet((prev) => {
                  prev.add(source.id);
                  return new Set(prev);
                });

                if (isSelected) {
                  await API.fetch(
                    'DELETE',
                    `/API/v1/groups/${groupId}/sources`,
                    null,
                    {
                      source_ids: [source.id],
                    },
                  );
                } else {
                  await API.fetch(
                    'POST',
                    `/API/v1/groups/${groupId}/sources`,
                    null,
                    {
                      source_ids: [source.id],
                    },
                  );
                }

                await fetchSources();

                setLoadingSet((prev) => {
                  prev.delete(source.id);
                  return new Set(prev);
                });
              }}
            />
            </td>
          );
        }}
      />

      {editModal.isOpen 
        ? <GroupEditModal
            editModal={editModal}
            handleEdit={(name, isPublic, group) => {
              handleEdit(name, isPublic, group)
              setEditModal({isOpen: false, group: null})
            }}
            clearEdit = {() => setEditModal({isOpen: false, group: null})}
          /> 
        : ''}

        {currentUser?.is_super_admin === true ? (
          <CopyGroupToWorkspacePopup
            isVisible={copyGroupModal.isActive}
            groupId={group.id}
            onClose={() => setCopyGroupModal({ isActive: false })}
          />
        ) : (
          ''
        )}
    </div>
  );
}
